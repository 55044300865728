import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { useState } from 'react'

import { ErrorBoundaryContext, useErrorBoundary } from '../../hooks/useErrorBoundary'

const ErrorBoundaryProvider = ({ children }: React.PropsWithChildren) => {
  const [error, setError] = useState(false)

  return <ErrorBoundaryContext.Provider value={{ error, setError }}>{children}</ErrorBoundaryContext.Provider>
}

const SentryErrorBoundaryConsumer = ({ children }: React.PropsWithChildren) => {
  const { setError } = useErrorBoundary()

  return <SentryErrorBoundary onError={() => setError(true)}>{children}</SentryErrorBoundary>
}

export const ErrorBoundary = ({ children }: React.PropsWithChildren) => {
  return (
    <ErrorBoundaryProvider>
      <SentryErrorBoundaryConsumer>{children}</SentryErrorBoundaryConsumer>
    </ErrorBoundaryProvider>
  )
}

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

const request = endpoints.Favorite_ListFavorite.getApiRequest()

export const useGetFavoriteSponsorIds = (options: {
  params: Favorite_ListFavoriteRequestPathParams
  enabled?: boolean
}) => {
  const { params, enabled } = options
  return useQuery({
    queryKey: queryKeys.getFavoriteSponsorIds(params),
    queryFn: () => request({ params }),
    enabled,
  })
}

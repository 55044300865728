import { useEffect, useState } from 'react'

import { useGetTerminatedSponsors } from '../../api/hooks/sponsor'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { BookOfBusinessFilterContext, type BookOfBusinessFilter } from '../../context/BookOfBusinessFilterContext'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { BenefitStatus, filterSponsorsBenefitsByBenefitStatus } from '../../utils/benefit'

import { useEmptyText } from './useEmptyText'

const sponsorStatus = 'terminated'

export const TerminatedSponsors = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const [filter, setFilter] = useState<BookOfBusinessFilter>({ sponsorStatus, ...sort })
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounced(searchQuery, 500)
  const auth = useAuth()
  const enabled = auth.user?.permissions.isInternalUser === false
  const terminatedSponsors = useGetTerminatedSponsors(
    {
      query: {
        q: debouncedSearchQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )
  const emptyText = useEmptyText(
    debouncedSearchQuery,
    'There are no clients here yet. When a client has terminated all coverages with Sun Life, it will appear here.',
  )
  const sponsors = terminatedSponsors.data?.bookOfBusiness?.sponsors ?? []

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchQueryChange() {
      if (searchQuery.length > 0 && searchQuery === debouncedSearchQuery) onPageChange(1)
    },
    [debouncedSearchQuery, onPageChange, searchQuery],
  )

  useEffect(() => {
    setFilter((prev) => ({ ...prev, q: debouncedSearchQuery }))
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery) {
      analytics.track('book of business table:search terminated employers', {
        query: debouncedSearchQuery,
      })
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery.length > 0 && sponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty terminated employers query`,
      })
  }, [debouncedSearchQuery, sponsors.length])

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query)
  }

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download terminated employers',
    })
  }

  return (
    <BookOfBusinessFilterContext.Provider value={filter}>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.terminated}
        downloadButtonProps={{
          disabled: sponsors.length == 0,
          text: 'Download terminated clients',
          fileType: sponsorStatus,
          onDownloadClick: () => handleDownloadClick(),
        }}
        label="Search in clients..."
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
      />
      <QueryResultUnconditional query={terminatedSponsors}>
        {(data) => {
          const filteredSponsors = filterSponsorsBenefitsByBenefitStatus(sponsors, [BenefitStatus.TERMINATED])

          return (
            <BookOfBusinessTable
              type="terminated"
              sponsors={filteredSponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </BookOfBusinessFilterContext.Provider>
  )
}

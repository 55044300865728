import { useRecentlyViewedSponsorStore } from './store/useRecentlyViewedSponsorStore'
import { useRecentlyViewedSponsors } from './useRecentlyViewedSponsors'

/**
 * Returns the count of recently viewed sponsors.
 */
export function useRecentlyViewedSponsorsCount() {
  const recentlyViewedSponsors = useRecentlyViewedSponsors()
  const [entries] = useRecentlyViewedSponsorStore()
  // Return the count from the API if available, otherwise return the count from the store.
  return recentlyViewedSponsors.data?.bookOfBusiness?.totalRecords ?? entries.length
}

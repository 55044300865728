.dateContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Sun Life New Text Regular;
}

.dateInnerContainer {
  margin-right: 2rem;
}

.stackY16 {
  margin-bottom: 1rem;
}

.stackY64 {
  margin-bottom: 4rem;
}

.cardLabel {
  font-family: Sun Life New Text Regular;
  font-size: 0.875rem;
  line-height: 12px;
}

.cardInnerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

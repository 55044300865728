import Hotjar from '@hotjar/browser'

import { getHotjarId, getHotjarVersion } from './getClientConfig'

export function initHotjar() {
  const siteId = getHotjarId()
  const hotJarVersion = getHotjarVersion()

  Hotjar.init(+siteId, +hotJarVersion)
}

import { Body, Divider, Link } from '@component-library/helios'
import { useCallback, useMemo } from 'react'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { BenefitCard } from '../../components/BenefitCard/BenefitCard'
import { Glossary } from '../../components/Glossary/Glossary'
import { QueryResult } from '../../components/QueryResult/QueryResult'
import { sortPolicies } from '../../utils/benefit'
import {
  getConfigForGenericDentalIdCardUrl,
  getConfigForHealthNavigatorUrl,
  getConfigForOABrokerUrl,
} from '../../utils/getClientConfig'
import { filterCoveragesInPolicies, getCommissionsText, parseComissions } from '../../utils/policy'

import styles from './Benefits.module.css'
import { billingTypeMap } from './billingTypeMap'

type BenefitsProps = {
  policies?: Policy[]
  dentalPolicy?: string
}

type RedirectToOABrokerCommissionsParams = {
  policyNum: string
  payeeNumber: string
  policyPartNumber: string
}

export const DataTestId = {
  benefitCardWrapper: 'benefit-card-wrapper',
  dividerWrapper: 'divider-wrapper',
  glossaryWrapper: 'glossary-wrapper',
}

export const Benefits = ({ policies, dentalPolicy }: BenefitsProps) => {
  const getCommissionsPaidToDateFt = useGetFeatureToggleByName('BEX_COMMISSIONS_PAID_TO_DATE')

  const filteredAndSortedPolicies = useMemo<Policy[]>(() => {
    const filteredPolicies = filterCoveragesInPolicies(policies ?? [])
    return sortPolicies(filteredPolicies)
  }, [policies])

  const redirectToOABrokerCommissions = useCallback((param: RedirectToOABrokerCommissionsParams) => {
    if (!param.payeeNumber || !param.policyPartNumber || !param.policyNum) return

    const oaBrokerURL = getConfigForOABrokerUrl()
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `${oaBrokerURL}oabroker/Broker/bob/detail/commissions`

    const partNumInput = document.createElement('input')
    partNumInput.type = 'hidden'
    partNumInput.name = 'partNum'
    partNumInput.value = param.policyPartNumber.split('-')[1]
    form.appendChild(partNumInput)

    // pay to number
    const payToNumberInput = document.createElement('input')
    payToNumberInput.type = 'hidden'
    payToNumberInput.name = 'payeeNum'
    payToNumberInput.value = param.payeeNumber
    form.appendChild(payToNumberInput)

    // policy number
    const policyNumberInput = document.createElement('input')
    policyNumberInput.type = 'hidden'
    policyNumberInput.name = 'policyNum'
    policyNumberInput.value = param.policyNum
    form.appendChild(policyNumberInput)

    document.body.appendChild(form)

    form.target = 'slconnect'
    window.open('', 'slconnect')
    form.submit()
    document.body.removeChild(form)
  }, [])

  if (filteredAndSortedPolicies.length === 0) return null

  const dentalIdCardUrl = getConfigForGenericDentalIdCardUrl()
  const healthNavigatorUrl = getConfigForHealthNavigatorUrl()

  const benefitsForPolicies = (
    <QueryResult query={getCommissionsPaidToDateFt}>
      {(commissionsPaidToDateFt) =>
        filteredAndSortedPolicies.map((policy) => {
          const commissions = parseComissions(policy.commissionsPaidYearToDate)
          return (
            <div key={policy.policyNumber} className={styles.outerConatiner}>
              {commissionsPaidToDateFt.enabled && <h3>Policy #{policy.policyNumber}</h3>}
              <div className={styles.policyContainer}>
                {commissionsPaidToDateFt.enabled && (
                  <div>
                    {commissions === 0 ? (
                      <Body variant="medium-bold">{getCommissionsText(commissions)}</Body>
                    ) : (
                      <Link
                        onClick={() => {
                          redirectToOABrokerCommissions({
                            policyNum: policy.policyNumber,
                            payeeNumber: policy.payeeNumber,
                            policyPartNumber: policy.policyPartNumber,
                          })
                        }}
                      >
                        {getCommissionsText(commissions)}
                      </Link>
                    )}
                  </div>
                )}
                {!commissionsPaidToDateFt.enabled && <h3>Policy #{policy.policyNumber}</h3>}
                <Body color="caption">
                  Contract state:{' '}
                  {billingTypeMap[policy.adminType]
                    ? `${policy?.contractState.join(', ')} | ${billingTypeMap[policy.adminType]}`
                    : `${policy?.contractState.join(', ')}`}
                </Body>
              </div>
              <BenefitCard
                policy={policy}
                dentalPolicy={dentalPolicy}
                dentalIdCardUrl={dentalIdCardUrl}
                healthNavigatorUrl={healthNavigatorUrl}
              />
            </div>
          )
        })
      }
    </QueryResult>
  )

  return (
    <div className={styles.benefitOuterWrapper}>
      <div className={styles.benefitWrapper}>
        <div data-testid={DataTestId.benefitCardWrapper}>{benefitsForPolicies}</div>
        <div className={styles.stackY64} data-testid={DataTestId.dividerWrapper}>
          <Divider />
        </div>
        <div className={styles.stackY32} data-testid={DataTestId.glossaryWrapper}>
          <Glossary />
        </div>
      </div>
    </div>
  )
}

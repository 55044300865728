import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'
import type { FeatureToggleName } from '../featureToggle'

export type UseGetFeatureToggleByNameReturn = ReturnType<typeof useGetFeatureToggleByName>

const request = endpoints.FeatureToggle_Get.getApiRequest()

export const useGetFeatureToggleByName = (name: FeatureToggleName) => {
  return useQuery({
    queryKey: queryKeys.getFeatureToggleByName(name),
    queryFn: () => request({ params: { name } }),
  })
}

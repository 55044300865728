.actionCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionCell > div {
  flex: 0 0 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.star {
  width: 1rem;
}

.clientName *:first-child {
  max-width: 20vw;
  white-space: initial;
}

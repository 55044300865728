export const getConfigForCommonloginUrl = () => {
  return window.config.commonLoginUrl ?? null
}

export const getConfigForSLCUrl = () => {
  return window.config.slcUrl ?? null
}

export const getConfigForOABrokerUrl = () => {
  return window.config.oaBrokerUrl ?? null
}

export const getCurrentEnvironment = () => {
  return window.config.env ?? null
}

export const getConfigForGenericDentalIdCardUrl = () => {
  return window.config.genericDentalIdUrl ?? null
}

export const getConfigForHealthNavigatorUrl = () => {
  return window.config.healthNavigatorUrl ?? null
}

export const getConfigForMaxwellHealthUrl = () => {
  return window.config.maxwellHealthUrl ?? null
}

export const getConfigForHelpCenterUrl = () => {
  return window.config.helpCenterUrl ?? null
}

export const getHotjarId = () => {
  return window.config.hotjarId ?? null
}

export const getHotjarVersion = () => {
  return window.config.hotjarVersion ?? null
}

export const isLocal = window.config.env === 'local'

export const isQA = window.config.env === 'qa-dcs'

export const isStaging = window.config.env === 'stage-dcs'

export const isProduction = window.config.env === 'prod-dcs'

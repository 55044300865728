import { Progress } from '@component-library/helios'
import { useEffect, useState, type PropsWithChildren, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import { instance } from '../../api/axios'
import { useAuthLogout } from '../../api/hooks/useAuthLogout'
import { usePostAuth } from '../../api/hooks/usePostAuth'
import { usePostExtendAuth } from '../../api/hooks/usePostExtendAuth'
import { getConfigForCommonloginUrl } from '../../utils/getClientConfig'
import { AuthContext } from '../AuthContext/AuthContext'
import { AuthMonitor } from '../AuthMonitor/AuthMonitor'

const logoutPathname = '/logout'
const pathnamesWhitelist = [logoutPathname]

/**
 * The AuthProvider is responsible for managing the authentication state of the application.
 *
 * States:
 *  - idle: the user is not authenticated and no authentication attempt is in progress.
 *  - success: the user is authenticated.
 *  - error: the user is not authenticated and an authentication attempt failed.
 */
export function AuthProvider({ children }: PropsWithChildren) {
  const commonLoginUrl = getConfigForCommonloginUrl()

  const { pathname } = useLocation()
  const [user, setUser] = useState<User>()

  const [authToken, setAuthToken] = useAuthToken()
  const logout = useLogout()

  const [authEnabled, setAuthEnabled] = useState(false)

  const [customHeadersAvailable, setCustomHeadersAvailable] = useState(false)

  const auth = usePostAuth({ body: { token: authToken.token } }, { enabled: authEnabled })
  const extend = usePostExtendAuth({ body: { token: authToken?.token } }, { enabled: false })

  const setCustomHeaders = useCallback((user: User) => {
    instance.defaults.headers.common['X-Broker-ID'] = user?.brokerID
    instance.defaults.headers.common['X-Username'] = user?.username
    instance.defaults.headers.common['X-Pay-To-Numbers'] = JSON.stringify(user?.payToNumbers)
    setCustomHeadersAvailable(true)
  }, [])

  useEffect(() => {
    const isLogoutPathname = pathname === logoutPathname
    const isRedirect = !!document.referrer
    const isRedirectFromLogout = isLogoutPathname && isRedirect
    if (!authEnabled && !isRedirectFromLogout) {
      setAuthEnabled(true)
    }
  }, [authEnabled, pathname])

  // If authentication/authorization succeed, set user.
  useEffect(() => {
    if (auth.isSuccess) {
      if (auth.data.status === 'authenticated') {
        setCustomHeaders(auth.data.user)
        setUser(auth.data.user)
      }
    }
  }, [auth.data?.status, auth.data?.user, auth.isSuccess, setCustomHeaders])

  // If session extension succeed, set user.
  useEffect(() => {
    if (extend.isSuccess) {
      if (extend.data.status === 'authenticated') {
        setCustomHeaders(extend.data.user)
        setUser(extend.data.user)
      }
    }
  }, [extend.data?.status, extend.data?.user, extend.isSuccess, setCustomHeaders])

  // Store the auth token when the user is set.
  useEffect(() => {
    if (user) {
      setAuthToken({
        token: user.token,
        createdAt: user.createdAt,
      })
    }
  }, [setAuthToken, user])

  // If authentication fails redirect to login.
  useEffect(() => {
    const isError = auth.isError
    const isUnauthenticated = auth.data?.status === 'unauthenticated' || extend.data?.status === 'unauthenticated'
    const isPathnameWhitelisted = pathnamesWhitelist.includes(pathname)

    if ((isError || isUnauthenticated) && !isPathnameWhitelisted) {
      window.location.replace(`${commonLoginUrl}login/10`)
    }
  }, [auth.data?.status, auth.isError, commonLoginUrl, extend.data?.status, pathname])

  // Render contents when user is authenticated or the current path is whitelisted.
  const isAuthenticated = auth.data?.status === 'authenticated' || extend.data?.status === 'authenticated'
  const isPathnameWhitelisted = pathnamesWhitelist.includes(pathname)
  const hasAuthToken = Boolean(authToken.token)

  if ((isAuthenticated && hasAuthToken && customHeadersAvailable) || isPathnameWhitelisted) {
    return (
      <AuthContext.Provider value={{ user, authToken, logout }}>
        {!isPathnameWhitelisted && <AuthMonitor />}
        {children}
      </AuthContext.Provider>
    )
  }

  // Otherwise render indeterminate progress bar.
  return <Progress id="authProviderLoading" label="" variant="indeterminate" />
}

const useLogout = () => {
  const [{ token }, setAuthToken] = useAuthToken()
  const { isSuccess, refetch } = useAuthLogout({ body: { token } }, { enabled: false })

  const logout = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (isSuccess) {
      setAuthToken({ token: '', createdAt: 0 })
      location.href = '/logout' // Full refresh needed to clear httpOnly session cookie.
    }
  }, [isSuccess, setAuthToken])

  return logout
}

function useAuthToken() {
  return useLocalStorageState<AuthToken>('bex.auth.sid', {
    defaultValue: { token: '', createdAt: 0 },
  })
}

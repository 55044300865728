import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export const useAuthLogout = endpoints.Auth_Logout.createRequestHook(
  (request, { body }, { enabled }: { enabled: boolean }) => {
    return useQuery({
      queryKey: queryKeys.logout(body),
      queryFn: () => request({ body }),
      enabled,
    })
  },
)

import { Heading } from '@component-library/helios'
import type { PropsWithChildren } from 'react'

import styles from './PageTitle.module.css'

export const PageTitle = ({ children }: PropsWithChildren) => {
  return (
    <Heading variant="1" as="h1" className={styles.pageTitle}>
      {children}
    </Heading>
  )
}

import { useCallback } from 'react'

import type { SponsorId } from '../utils/recentlyViewedSponsor'
import { addOrUpdateRecentlyViewedSponsor } from '../utils/recentlyViewedSponsor'

import { useRecentlyViewedSponsorStore } from './store/useRecentlyViewedSponsorStore'
import { useAuth } from './useAuth'

/**
 * Custom hook that returns a function to add a recently viewed sponsor.
 * The function checks if the user is not an internal user and adds the sponsor to the recently viewed entries.
 */
export function useAddRecentlyViewedSponsor() {
  const auth = useAuth()
  const [, setEntries] = useRecentlyViewedSponsorStore()
  const enabled = auth.user?.permissions.isInternalUser === false

  const addRecentlyViewedSponsor = useCallback(
    (sponsorId: SponsorId) => {
      if (!enabled) return
      setEntries((entries) => addOrUpdateRecentlyViewedSponsor(entries, sponsorId))
    },
    [enabled, setEntries],
  )

  return addRecentlyViewedSponsor
}

.help {
  padding-bottom: 4rem;
}

.help h3 {
  font-size: 1.75rem;
  font-weight: 300;
}

.help h4 {
  font-size: 1.5rem;
  font-weight: 300;
}

.help a {
  font-size: 1rem;
  font-weight: 700;
  color: var(--brand-tertiary-800);
  text-decoration-line: none;
}

.helpContent {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.5rem;
  overflow: hidden;
  color: rgb(124, 134, 205);
  font-family: sans-serif;
  margin-top: 1.5rem;
}

.helpContent > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.helpContent .divider {
  padding-right: 1.5rem;
}

.helpContent .stackY {
  margin-bottom: 0.5rem;
}

@media (max-width: 904px) {
  .helpContent {
    grid-template-columns: 1fr;
  }

  .helpContent > div {
    padding-bottom: 1rem;
  }

  .helpContent .divider {
    display: none;
  }
}

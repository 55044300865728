import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

const request = endpoints.Sponsor_ActionMenu.getApiRequest()

export const usePostActionMenus = (body: Sponsor_ActionMenuRequest) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => request({ body }),
    mutationKey: queryKeys.getActionMenus(body),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKeys.getActionMenus() })
    },
  })
}

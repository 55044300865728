import { Footer } from '@component-library/helios'
import type { FC } from 'react'

import { PageContent } from '../PageContent/PageContent'

import * as styles from './LegalFooter.module.css'

export const LegalFooter: FC = () => (
  <PageContent>
    <Footer
      content={{
        heading: "Life's brighter under the sun",
        body: (
          <div className={styles.legalFooterBody}>
            {/* TODO: Use component from `@component-library/helios` instead of className. */}
            <p className="body-small">
              Except as noted, insurance products and prepaid dental products are underwritten or provided by Sun Life
              Assurance Company of Canada (Wellesley Hills, MA) (&quot;SLOC&quot;). In certain states prepaid dental
              products are provided by prepaid dental companies affiliated with SLOC. In New York, insurance products
              and prepaid dental products are underwritten or provided by Sun Life and Health Insurance Company (U.S.)
              (Lansing, MI) (&quot;SLHICUS&quot;).
            </p>
            {/* TODO: Use component from `@component-library/helios` instead of className. */}
            <p className="body-small">
              © 2024 Sun Life Assurance Company of Canada, Wellesley Hills, MA 02481. All rights reserved. The Sun Life
              name and logo are registered trademarks of Sun Life Assurance Company of Canada. Visit us at{' '}
              <a href="https://www.sunlife.com/us" target="_blank" rel="noreferrer">
                www.sunlife.com/us
              </a>
              .
            </p>
          </div>
        ),
      }}
      menus={undefined}
      language={undefined}
      utilityMenu={{
        items: [
          {
            href: 'https://www.sunlife.com/sl/pslf-worldwide/en/legal/',
            title: 'Legal',
            target: '_blank',
          },
          {
            href: 'https://www.sunlife.com/sl/pslf-worldwide/en/privacy/',
            title: 'Privacy',
            target: '_blank',
          },
          {
            href: 'https://www.sunlife.com/sl/pslf-worldwide/en/security/',
            title: 'Security',
            target: '_blank',
          },
        ],
      }}
    />
  </PageContent>
)

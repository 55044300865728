.viewSponsorSection {
  padding-bottom: 2rem;
}

.breadcrumbs {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  line-height: 1;
}

.breadcrumbs > :global(.breadcrumb-item),
.breadcrumbs > :global(.current-item) {
  margin: 0 !important;
}

.margin2rem {
  margin-top: 2rem;
}

marginTopHalfrem {
  margin-top: 0.5rem;
}

.removeMargins {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

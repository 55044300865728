.outerContainer {
  padding: 0 1.5rem;
}

.errorMessage {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1rem;
}

.contactInfoInnerContainer a {
  text-decoration-line: none;
  color: var(--brand-tertiary-800);
  font-weight: 700;
}

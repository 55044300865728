import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type UsePostAuthReturn = ReturnType<typeof usePostAuth>

export const usePostAuth = endpoints.Auth_Auth.createRequestHook(
  (request, { body }, options: { enabled: boolean } = { enabled: true }) => {
    return useQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps -- This query depends on token, however when this query run it receives a new token which cause infinite loop.
      queryKey: queryKeys.login(),
      queryFn: () => request({ body }),
      ...options,
    })
  },
)

.container {
  position: fixed;
  right: -100%;
  bottom: 0;
  opacity: 0;
  transition: ease-in-out 0.2s all;
  box-shadow: 0 4px 8px 3px #00000014;
  background-color: var(--white);
}

.container :global(.modal-content) {
  max-height: none !important;
}

.visible {
  opacity: 1;
  right: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3px !important; /* To make outline visible above modal overflow. */
}

.allowContactGroup {
  display: flex;
  flex-flow: row;
  gap: 2rem;
}

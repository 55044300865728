import { Breadcrumbs, Link } from '@component-library/helios'
import type { ComponentProps, FC } from 'react'
import { useEffect, useState } from 'react'
import { Route, Routes, useParams, Link as ReactRouterDomLink, useLocation } from 'react-router-dom'

import { useGetSponsorById } from '../api/hooks/useGetSponsorById'
import type { TableType } from '../components/BookOfBusinessTable/BookOfBusinessTable'
import { HelpContent } from '../components/HelpContent/HelpContent'
import { PageContent } from '../components/PageContent/PageContent'
import { QueryResult } from '../components/QueryResult/QueryResult'
import { SponsorInfoPane } from '../components/SponsorInfoPane/SponsorInfoPane'
import { useAddRecentlyViewedSponsor } from '../hooks/useAddRecentlyViewedSponsor'
import { useAuth } from '../hooks/useAuth'
import { useAutoScrollTop } from '../hooks/useAutoScrollTop'
import { useDefaultTabNavigation } from '../hooks/useDefaultTabNavigation'
import { getDentalPolicyFromPolicies } from '../utils/policy'

import { Benefits } from './Benefits/Benefits'
import styles from './ViewSponsor.module.css'

export const ViewSponsor: FC = () => {
  const auth = useAuth()
  const { state } = useLocation()
  const { sponsorId } = useParams()
  const addRecentlyViewedSponsor = useAddRecentlyViewedSponsor()

  const [type, setType] = useState<TableType>(state?.type ?? 'all')

  const getSponsorById = useGetSponsorById({ params: { id: sponsorId } }, { enabled: typeof sponsorId === 'string' })

  useDefaultTabNavigation(tabPathnames, defaultTabPathname)

  useAutoScrollTop()

  useEffect(() => {
    if (state?.type != null && state.type !== type) setType(state.type)
  }, [state?.type, type])

  useEffect(() => {
    if (sponsorId) {
      addRecentlyViewedSponsor(parseInt(sponsorId))
    }
  }, [addRecentlyViewedSponsor, sponsorId])

  const isInternalUser = auth.user?.permissions.isInternalUser ?? false

  let breadcrumbBobHref: string
  switch (type) {
    case 'all':
      breadcrumbBobHref = '/all-clients'
      break
    case 'recentlyViewed':
      breadcrumbBobHref = '/recently-viewed'
      break
    case 'starred':
      breadcrumbBobHref = '/starred'
      break
    case 'terminated':
      breadcrumbBobHref = '/terminated'
      break
    default:
      breadcrumbBobHref = '/all-clients'
      break
  }

  return (
    <>
      <QueryResult query={getSponsorById}>
        {({ sponsor }) => {
          const items: ComponentProps<typeof Breadcrumbs>['items'] = [
            {
              href: breadcrumbBobHref,
              id: 'bookOfBusiness',
              label: 'Book of business',
            },
            {
              id: `${sponsor.sponsorName ?? 'sponsorName'}`,
              label: `${sponsor.sponsorName ?? ''}`,
            },
          ]

          const dentalPolicy = getDentalPolicyFromPolicies(sponsor.policies)

          return (
            <>
              <PageContent>
                {!isInternalUser && (
                  <Breadcrumbs
                    items={items}
                    className={styles.breadcrumbs}
                    itemRender={(props, index) => {
                      const isLast = index === items?.length - 1
                      return isLast ? undefined : (
                        <Link as={ReactRouterDomLink} to={props.href ?? '#'} size="sm">
                          {props.label}
                        </Link>
                      )
                    }}
                  />
                )}
                <div className={styles.margin2rem}>
                  <SponsorInfoPane sponsor={sponsor} dentalPolicy={dentalPolicy} isInternalUser={isInternalUser} />
                </div>
              </PageContent>
              <main className={styles.viewSponsorSection}>
                <PageContent>
                  <Routes>
                    <Route
                      path={tabPathname.benefits}
                      element={<Benefits policies={sponsor.policies} dentalPolicy={dentalPolicy} />}
                    />
                    <Route path={tabPathname.formsContracts} element={<h2>Forms & Contracts</h2>} />
                    <Route path={tabPathname.commissions} element={<h2>Commissions</h2>} />
                  </Routes>
                </PageContent>
              </main>
            </>
          )
        }}
      </QueryResult>

      <div>
        <PageContent>
          <HelpContent />
          <hr className={styles.removeMargins} />
        </PageContent>
      </div>
    </>
  )
}

enum tabPathname {
  benefits = `/benefits`,
  formsContracts = `/forms-contracts`,
  commissions = `/commissions`,
}

const defaultTabPathname = tabPathname.benefits

const tabPathnames = Object.values(tabPathname)

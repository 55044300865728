import { Outlet } from 'react-router-dom'

import { useErrorBoundary } from '../../hooks/useErrorBoundary'
import { Error } from '../../pages/Error/Error'
import { LegalFooter } from '../LegalFooter/LegalFooter'
import { TopNav } from '../TopNav/TopNav'

import style from './MainLayout.module.css'

export const MainLayout = () => {
  const { error } = useErrorBoundary()
  return (
    <div className={style.mainLayout}>
      <TopNav />
      <main className={style.mainLayoutContent}>{error ? <Error type="generic" /> : <Outlet />}</main>
      <footer>
        <LegalFooter />
      </footer>
    </div>
  )
}

import { Body, Link } from '@component-library/helios'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FC } from 'react'

import styles from './ContactInfo.module.css'

export enum ContactType {
  StopLoss,
  GroupServices,
  EnrollServices,
  HealthNavAssist,
}
interface ContactDetails {
  copy: string
  contactEmail?: string
  phone?: string
}

const contactDetailsMap: Record<ContactType, ContactDetails> = {
  [ContactType.StopLoss]: { copy: 'Stop Loss assistance' },
  [ContactType.GroupServices]: {
    copy: 'Group assistance',
    contactEmail: 'clientservices@sunlife.com',
    phone: '800-247-6875',
  },
  [ContactType.EnrollServices]: { copy: 'Member enrollment adjustments', contactEmail: 'customeradvocacy@sunlife.com' },
  [ContactType.HealthNavAssist]: { copy: 'Health Navigator assistance' },
}

interface Props {
  contactType: ContactType
  email?: string
  name?: string
}

export const ContactInfo: FC<Props> = ({ contactType, email, name }) => {
  const { copy, contactEmail, phone } = contactDetailsMap[contactType]

  const displayEmail = contactEmail ?? email
  const displayNameOrEmail = name ?? displayEmail

  return (
    <div className={styles.contactInfo}>
      <Body variant="small" as="div">
        {copy}
      </Body>
      <Body variant="medium-bold" as="div" className={styles.contactInfoInnerContainer}>
        <Link href={`mailto:${displayEmail}`} title={displayEmail}>
          <FontAwesomeIcon icon={faEnvelope} /> {displayNameOrEmail}
        </Link>
      </Body>
      {phone && (
        <Body variant="medium" as="div" className={styles.contactInfoInnerContainer}>
          <Link href={`tel:${phone}`}>
            <FontAwesomeIcon icon={faPhone} /> {phone}
          </Link>
        </Body>
      )}
    </div>
  )
}

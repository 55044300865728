.bookOfBusinessActionBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  gap: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.searchContainer {
  flex: 0.75 1 0;
}

.downloadContainer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

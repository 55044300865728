import { HelperText, Link, Progress } from '@component-library/helios'
import type { UseQueryResult } from '@tanstack/react-query'
import { type ReactNode } from 'react'

import styles from './QueryResult.module.css'

export type QueryResultProps<TData = unknown, TError = unknown> = {
  query: UseQueryResult<TData, TError>
  children: (data: TData) => ReactNode
  loading?: ReactNode
  error?: ReactNode
}

export function QueryResult<TData = unknown, TError = unknown>({
  query,
  children,
  loading,
  error,
}: QueryResultProps<TData, TError>) {
  if ((query.isLoading || query.isFetching) && !query.isRefetching) {
    return (
      loading ?? (
        <div className={styles.loading}>
          <Progress id="queryResultLoading" label="" variant="indeterminate" />
        </div>
      )
    )
  }

  if (query.isError) {
    return (
      error ?? (
        <div className={styles.error}>
          <HelperText id="loadingError" error>
            <span>An error has occurred.</span>
            &nbsp;
            <Link href="#" variant="primary" size="sm" onClick={() => query.refetch()}>
              Try again
            </Link>
          </HelperText>
        </div>
      )
    )
  }

  if (query.isSuccess) return children(query.data)

  return null
}

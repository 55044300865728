import { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetFavoriteSponsorIds } from '../api/hooks/useGetFavoriteSponsorIds'
import { getBookOfBusinessTabByPathname } from '../pages/BookOfBusiness'
import { trackView } from '../utils/analytics'

import { useActiveSponsorCount } from './useActiveSponsorCount'
import { useAuth } from './useAuth'
import { useTerminatedSponsorCount } from './useTerminatedSponsorCount'

export function useViewBookOfBusinessTracker() {
  const location = useLocation()
  const auth = useAuth()
  const isBookOfBusinessTab = getBookOfBusinessTabByPathname(location.pathname) !== undefined
  const getFavorites = useGetFavoriteSponsorIds({
    params: { brokerId: auth.user?.brokerID ?? '' },
    enabled: isBookOfBusinessTab,
  })
  const activeSponsorCount = useActiveSponsorCount({ enabled: isBookOfBusinessTab })
  const terminatedSponsorCount = useTerminatedSponsorCount({ enabled: isBookOfBusinessTab })
  const [stack, setStack] = useState<Array<string>>([])

  const track = useCallback(function ({
    url,
    starredTotal,
    sponsorTotal,
  }: {
    url: string
    starredTotal: number
    sponsorTotal: number
  }) {
    trackView({
      ev_type: 'page_imp',
      ev_action: 'page_imp',
      page_canonical_url: url,
      broker_portal_starred_rows: `${starredTotal}`,
      broker_portal_active_clients: `${sponsorTotal}`,
    })
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    function tick() {
      const ready = activeSponsorCount !== undefined && terminatedSponsorCount !== undefined && getFavorites.isSuccess

      if (ready && stack.length) {
        stack.forEach((url) =>
          track({
            url,
            starredTotal: getFavorites.data.sponsorIds.length,
            sponsorTotal: activeSponsorCount + terminatedSponsorCount,
          }),
        )
        setStack([])
      }
      timeoutId = setTimeout(tick, 100)
    }
    tick()
    return () => {
      clearInterval(timeoutId)
    }
  }, [activeSponsorCount, getFavorites.data, getFavorites.isSuccess, stack, terminatedSponsorCount, track])

  return function (url: string) {
    setStack((stack) => [...stack, url])
  }
}

import { matchPath } from 'react-router-dom'

type PageMeta = {
  category: string
  subCategory: string
}

// TODO add routes to a typescript string union or enum

const pageMeta: Record<string, PageMeta> = {
  '/all-clients': { category: 'Book of business', subCategory: 'Active Employer' },
  '/recently-viewed': { category: 'Book of business', subCategory: 'Recently viewed' },
  '/starred': { category: 'Book of business', subCategory: 'Starred' },
  '/terminated': { category: 'Book of business', subCategory: 'Terminated' },
  '/sponsor/:sponsorId/*': {
    category: 'Book of business',
    subCategory: 'View Employer',
  },
  '/commissions': { category: 'Commissions', subCategory: 'Commissions' },
  '/account': { category: 'Account', subCategory: 'Account' },
  '/logout': { category: 'Logout', subCategory: 'Logout' },
  '/': { category: 'Home', subCategory: 'Home' },
}

export const getPageMeta = (pathname: string) => {
  for (const pattern of Object.keys(pageMeta)) {
    if (matchPath(pattern, pathname)) {
      return pageMeta[pattern]
    }
  }
}

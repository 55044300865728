import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type usePostExtendAuthReturn = ReturnType<typeof usePostExtendAuth>

export const usePostExtendAuth = endpoints.Auth_Extend.createRequestHook(
  (request, { body }, options: { enabled: boolean } = { enabled: true }) => {
    return useQuery({
      queryKey: queryKeys.extend(body),
      queryFn: () => request({ body }),
      ...options,
    })
  },
)

import { useCallback, useState } from 'react'

export type SponsorSortField = 'name' | 'date_next_renew'

export interface SponsorSort {
  sortKey: SponsorSortField
  sortDirection: SortDirection
}
interface Pagination {
  page: number
  pageSize: number
}
// because the query expects strings
export interface QueryPagination {
  page: string
  pageSize: string
}

export const useBookOfBusinessPagination = () => {
  const [sort, setSort] = useState<SponsorSort>({ sortKey: 'name', sortDirection: 'asc' })
  const [pagination, setPagination] = useState<Pagination>({ page: 1, pageSize: 10 })

  const handleSortChange = useCallback((field: SponsorSortField, direction: 'desc' | 'asc') => {
    setSort({ sortKey: field, sortDirection: direction })
  }, [])

  const handlePageChange = useCallback((page: number) => {
    setPagination((prev) => ({ page, pageSize: prev?.pageSize ?? 10 }))
  }, [])

  const handleRowsPerPageChange = useCallback((pageSize: number) => {
    setPagination(() => ({ page: 1, pageSize }))
  }, [])

  const convertPaginationToString = useCallback(
    (pagination: Pagination): QueryPagination => ({
      page: String(pagination?.page || 1),
      pageSize: String(pagination?.pageSize || 10),
    }),
    [],
  )

  return {
    sort,
    pagination: convertPaginationToString(pagination),
    onSortChange: handleSortChange,
    onPageChange: handlePageChange,
    onRowsPerPageChange: handleRowsPerPageChange,
  }
}

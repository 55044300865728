import { useMemo } from 'react'

import type { BookOfBusinessFilter } from '../../context/BookOfBusinessFilterContext'

const SEARCH_MESSAGE =
  'There are no clients that match your search. Please check the spelling of the client name or the accuracy of the policy number.'

/**
 * @param query
 * the query to check
 * @param defaultMesage
 * the default message to display when not searching
 * @returns
 * empty search message text if there is a search query param (ie currently "searching") or a default message if not
 */
export const useEmptyText = (query: BookOfBusinessFilter['q'], defaultMesage: string): string =>
  useMemo(() => (query ? SEARCH_MESSAGE : defaultMesage), [defaultMesage, query])

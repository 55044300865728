import { DateTime } from 'luxon'

import type { BenefitStatus } from '../../utils/benefit'

export type Variant = 'info' | 'error' | 'success' | 'warning'

export const statusToLabel: Record<BenefitStatus, string> = {
  'IN PROCESS': 'In process',
  'RISK APPROVED': 'Risk approved',
  'IN FORCE': 'In force',
  TERMINATED: 'Terminated',
}

export const getBenefitPolicyStatusLabel = (
  currentBenefitEffectiveDate: string,
  currentBenefitPolicyStatus: string,
): string => {
  let label: string
  switch (currentBenefitPolicyStatus.toLowerCase()) {
    case 'in process':
    case 'commitment pend':
      label = statusToLabel['IN PROCESS']
      break
    case 'risk approved':
      if (
        currentBenefitPolicyStatus.toLowerCase() === 'risk approved' &&
        DateTime.fromMillis(new Date(currentBenefitEffectiveDate).getTime()) <= DateTime.now()
      ) {
        label = statusToLabel['IN FORCE']
      } else {
        label = statusToLabel[currentBenefitPolicyStatus.toUpperCase() as never]
      }
      break
    default:
      // all other statuses are a direct 1-to-1 map
      label = statusToLabel[currentBenefitPolicyStatus.toUpperCase() as never]
      break
  }

  return label
}

export const statusToVariant: Record<NonNullable<string>, Variant> = {
  'IN PROCESS': 'info',
  'RISK APPROVED': 'success',
  'IN FORCE': 'success',
  TERMINATED: 'error',
}

export const statusToMessage: Record<NonNullable<string>, string> = {
  'IN PROCESS':
    'Sun Life has received the commitment for this benefit. Upon receipt of all required documents, benefit details will be reviewed for final approval.',
  'RISK APPROVED':
    'Risk has been accepted by Sun Life and approved for the client. The contract will be delivered shortly.',
  'IN FORCE': 'The policy is now active and Sun Life will start accepting eligible claims.',
  TERMINATED: 'The benefit has been cancelled.',
}

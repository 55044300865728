.contactInfo > h5 {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.contactInfoInnerContainer {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.contactInfoInnerContainer a {
  text-decoration-line: none;
  color: var(--brand-tertiary-800);
  font-weight: 700;
}

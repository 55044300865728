import { StrictMode } from 'react'
import ReactDOMClient from 'react-dom/client'

import { App } from './app'
import { initHotjar } from './utils/hotjar'
import { initSentry } from './utils/sentry'

initSentry()
initHotjar()

const rootElement = document.getElementById('root')

if (!rootElement) throw new Error('No root element')

const root = ReactDOMClient.createRoot(rootElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)

.topNav {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 8rem;
  padding-top: 0.5rem;
  padding-right: 8rem;
  padding-bottom: 2rem;
}

.logo {
  flex: 0 0 auto;
  width: 120px;
}

.topNavLinks {
  flex: 1 1 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  padding: 0 1rem;
}

.topNavLink {
  display: block;
  padding: 1rem;
  color: #004c6c;
}

.topNavLinkSpacer {
  flex: 1 1 auto;
}

.support {
  background: white;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 250ms cubic-bezier(0, 1.2, 0.175, 0.9);
  z-index: 3;
  width: 100%;
  padding-top: 2rem;
}

.hr {
  margin: 12px auto;
  max-width: var(---max-width);
}

.collapsed {
  visibility: hidden;
}

.expanding,
.expanded {
  transform: translateY(0);
  box-shadow: 0 2px 20px 6px rgba(0, 0, 0, 0.1);
}

.mask {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  opacity: 0;

  --duration: 0.15s;

  transform: scale(0);
  transition:
    opacity var(--duration) ease,
    transform 0s ease var(--duration);
}

.collapsed {
  visibility: hidden;
}

.expanding,
.expanded {
  transition:
    opacity var(--duration) ease,
    transform 0s ease;
  transform: scale(1);
  opacity: 0.9;
}

import { Link as HeliosLink } from '@component-library/helios'
import type { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { PageContent } from '../../components/PageContent/PageContent'

import styles from './Error.module.css'

export const ErrorContainer: FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.errorContainer}>{children}</div>
}

type ErrorType = 'generic' | 'notFound'
type ErrorProps = {
  type: ErrorType
}

export const Error: FC<ErrorProps> = ({ type }) => {
  const ErrorComponent = componentByErrorType[type]
  return (
    <PageContent>
      <ErrorContainer>
        <ErrorComponent />
      </ErrorContainer>
    </PageContent>
  )
}

const GenericError: FC = () => {
  return (
    <>
      <img src="/public/images/Icons-info-lifeinsurance.svg" className={styles.errorSpacing} />
      <h1 className={styles.errorSpacing}>Oops, there&apos;s a cloud. Let&apos;s make it sunny again.</h1>
      <p>
        Please refresh the page and try again. If that doesn&apos;t help, one of these steps may help to resolve the
        issue:
      </p>
      <ol>
        <li>Make sure you have any pop-up or ad blockers disabled.</li>
        <li>Use the latest version of Chrome, Safari, Edge, or Firefox.</li>
        <li>
          To clear your cache in Chrome, see{' '}
          <HeliosLink href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noreferrer">
            Google&apos;s help page.
          </HeliosLink>
        </li>
      </ol>
    </>
  )
}

const NotFoundError: FC = () => {
  return (
    <>
      <h1>Sorry, the page you are looking for does not exist.</h1>
      <p>Please check the URL or return to Home.</p>
      <Link to="/">Return to Home</Link>
    </>
  )
}

const componentByErrorType: Record<ErrorType, FC> = {
  generic: GenericError,
  notFound: NotFoundError,
}

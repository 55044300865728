import { Divider } from '@component-library/helios'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faArrowUpRightFromSquare, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getConfigForHelpCenterUrl } from '../../utils/getClientConfig'

import styles from './HelpContent.module.css'

export const HelpContent = () => {
  const helpCenterUrl = getConfigForHelpCenterUrl()

  return (
    <div className={styles.help}>
      <h3>Need help?</h3>
      <div className={styles.helpContent}>
        <div>
          <div className={styles.stackY}>
            <h4>For commissions and licensing</h4>
            <div>
              <a href="mailto:ebg_commissions@sunlife.com" title="ebg_commissions@sunlife.com">
                <FontAwesomeIcon icon={faEnvelope} /> Commissions
              </a>
            </div>
            <div>
              <a href="mailto:ebg_licensing@sunlife.com" title="ebg_licensing@sunlife.com">
                <FontAwesomeIcon icon={faEnvelope} /> Licensing
              </a>
            </div>
            <div>
              <a href="tel:800-440-1311">
                <FontAwesomeIcon icon={faPhone} /> 800-440-1311
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.divider}>
            <Divider orientation="vertical" />
          </div>
          <div className={styles.stackY}>
            <h4>Get general or technical help</h4>
            <div>
              <a href="mailto:clientservices@sunlife.com" title="clientservices@sunlife.com">
                <FontAwesomeIcon icon={faEnvelope} /> Client services
              </a>
            </div>
            <div>
              <a href="tel:800-247-6875">
                <FontAwesomeIcon icon={faPhone} /> 800-247-6875
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.divider}>
            <Divider orientation="vertical" />
          </div>
          <div className={styles.stackY}>
            <h4>Get on-demand answers to your questions</h4>
            <div>
              <a href={helpCenterUrl} target="_blank" rel="noopener noreferrer">
                Visit the help center <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

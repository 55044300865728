/**
 * Enum for Benefit Status.
 */
export enum BenefitStatus {
  IN_PROCESS = 'IN PROCESS',
  RISK_APPROVED = 'RISK APPROVED',
  IN_FORCE = 'IN FORCE',
  TERMINATED = 'TERMINATED',
}

export function filterSponsorsBenefitsByBenefitStatus(
  sponsors: Array<Sponsor>,
  status: Array<BenefitStatus>,
): Array<Sponsor> {
  return sponsors.map((sponsor) => {
    const filteredBenefits: Array<string> = []
    sponsor.policies.forEach((policy) => {
      const filteredCoverages: Array<Coverage> = []
      policy.coverages.forEach((coverage) => {
        if (status.includes(coverage.status as BenefitStatus)) {
          if (!filteredBenefits.includes(coverage.benefit)) filteredBenefits.push(coverage.benefit)
          filteredCoverages.push(coverage)
        }
      })
      policy.coverages = filteredCoverages
    })
    return { ...sponsor, benefits: filteredBenefits }
  })
}

export function filterPoliciesBenefitsByBenefitStatus(
  policies: Array<Policy>,
  status: Array<BenefitStatus>,
): Array<Policy> {
  return policies.map((policy) => {
    const filteredCoverages: Array<Coverage> = []
    policy.coverages.forEach((coverage) => {
      if (status.includes(coverage.status as BenefitStatus)) {
        filteredCoverages.push(coverage)
      }
    })
    return { ...policy, coverages: filteredCoverages }
  })
}

/**
 * Sort benefit coverages based on their status and name.
 * @param coverages
 * @returns
 */
export function sortCoverages(coverages: Array<Coverage>): Array<Coverage> {
  return [...coverages].sort((a, b) => {
    // sort by status, terminated status has low priority
    if (a.status === 'TERMINATED' && b.status !== 'TERMINATED') return 1
    if (a.status !== 'TERMINATED' && b.status === 'TERMINATED') return -1
    // sort by name
    return a.benefit.localeCompare(b.benefit)
  })
}

/**
 * Sort policies based on their benefits status and policy number.
 * @param policies
 * @returns
 */
export function sortPolicies(policies: Array<Policy>): Array<Policy> {
  return [...policies].sort((a, b) => {
    // sort by all coverage terminated.
    if (allCoverageTerminated(a.coverages) && !allCoverageTerminated(b.coverages)) return 1
    if (!allCoverageTerminated(a.coverages) && allCoverageTerminated(b.coverages)) return -1
    // sort by policy number
    return a.policyNumber.localeCompare(b.policyNumber)
  })
}

/**
 * Indicate if all coverages are terminated.
 * @param coverages
 * @returns
 */
function allCoverageTerminated(coverages: Array<Coverage>): boolean {
  return coverages.every((coverage) => coverage.status === BenefitStatus.TERMINATED)
}

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetFavoriteSponsorIds } from '../../api/hooks/useGetFavoriteSponsorIds'
import { useToggleFavorite } from '../../api/hooks/useToggleFavorite'
import { useActiveSponsorCount } from '../../hooks/useActiveSponsorCount'
import { useAuth } from '../../hooks/useAuth'
import { trackView } from '../../utils/analytics'

import styles from './StarIcon.module.css'

interface StarIconProps {
  sponsorId: number
  className?: string
  onToggle?: (starred: boolean) => void
}

export const StarIcon = ({ sponsorId, className, onToggle }: StarIconProps) => {
  const { user } = useAuth()
  const track = useOptimisticTrackSponsorsCount()
  const getFavoriteSponsorIds = useGetFavoriteSponsorIds({ params: { brokerId: user?.brokerID ?? '' } })
  const [isStarred, setIsStarred] = useState(Boolean(getFavoriteSponsorIds.data?.sponsorIds.includes(sponsorId)))
  const { mutate, isPending } = useToggleFavorite()

  const handleClick = () => {
    // Prevent toggling star while loading
    if (!isPending) {
      const newValue = !isStarred
      setIsStarred(newValue) // Toggle star in the UI before request is finished
      onToggle?.(newValue)
      mutate(sponsorId)
      track(newValue ? 'favorite' : 'unfavorite')
    }
  }

  return (
    <div className={className}>
      <FontAwesomeIcon
        role="switch"
        aria-label={isStarred ? 'Unstar client' : 'Star client'}
        aria-disabled={isPending}
        aria-hidden={false}
        icon={isStarred ? fasStar : farStar}
        className={styles.starIcon}
        onClick={() => handleClick()}
      />
    </div>
  )
}

function useOptimisticTrackSponsorsCount() {
  const auth = useAuth()
  const location = useLocation()
  const getFavorites = useGetFavoriteSponsorIds({ params: { brokerId: auth.user?.brokerID ?? '' } })
  const enabled = auth.user?.permissions.isInternalUser === false
  const activeSponsorCount = useActiveSponsorCount({ enabled })

  const starredTotal = getFavorites.data?.sponsorIds.length ?? 0
  const sponsorTotal = activeSponsorCount ?? 0

  return function trackSponsorsCountOptimistically(action: 'favorite' | 'unfavorite') {
    // Skip, data not ready to track.
    // TODO: Use a queue.
    if (!getFavorites.isSuccess || activeSponsorCount === undefined) {
      return
    }

    trackView({
      ev_type: 'page_imp',
      ev_action: 'page_imp',
      page_canonical_url: window.location.origin + location.pathname,
      broker_portal_starred_rows: `${action === 'favorite' ? starredTotal + 1 : starredTotal - 1}`,
      broker_portal_active_clients: `${sponsorTotal}`,
    })
  }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAuth } from '../../hooks/useAuth'
import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

const request = endpoints.Favorite_GetFavorite.getApiRequest()

export const useToggleFavorite = () => {
  const auth = useAuth()
  const client = useQueryClient()
  const queryKey = queryKeys.getFavoriteSponsorIds({ brokerId: auth.user?.brokerID ?? '' })

  return useMutation({
    mutationFn: (sponsorId: number) =>
      request({ params: { brokerId: auth.user?.brokerID ?? '', sponsorId: `${sponsorId}` } }),
    onMutate: async (sponsorId) => {
      await client.cancelQueries({ queryKey })
      const previous = client.getQueryData(queryKey)
      client.setQueryData(queryKey, (old: Favorite_ListFavoriteResponse) => ({
        ...old,
        sponsorIds: old.sponsorIds.includes(sponsorId)
          ? old.sponsorIds.filter((id) => id !== sponsorId)
          : [...old.sponsorIds, sponsorId],
      }))
      return { previous }
    },
    onError: (err, sponsorId, context) => {
      client.setQueryData(queryKey, context?.previous)
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey })
    },
  })
}

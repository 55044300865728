import { createContext, useContext } from 'react'

interface ErrorBoundaryState {
  error: boolean
  setError: (hasError: boolean) => void
}

export const ErrorBoundaryContext = createContext<ErrorBoundaryState>({
  error: false,
  setError: () => {},
})

export const useErrorBoundary = () => useContext(ErrorBoundaryContext)

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export type usePostSiteminderTokenReturn = ReturnType<typeof usePostSiteminderToken>

export const usePostSiteminderToken = endpoints.Siteminder_Poll.createRequestHook(
  (request, { body }, options: { enabled: boolean } = { enabled: true }) => {
    return useQuery({
      queryKey: queryKeys.poll(body),
      queryFn: () => request({ body }),
      ...options,
    })
  },
)

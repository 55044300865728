import useLocalStorageState from 'use-local-storage-state'

import type { RecentViewedSponsorEntries } from '../../utils/recentlyViewedSponsor'

/**
 * Custom hook for managing recently viewed sponsor store.
 *
 * @returns A tuple containing the entries and a function to set the entries.
 */
export function useRecentlyViewedSponsorStore() {
  const [entries, setEntries] = useLocalStorageState<RecentViewedSponsorEntries>('recentlyViewedSponsors', {
    defaultValue: [],
  })
  return [entries, setEntries] as const
}

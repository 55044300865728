.loadingContainer {
  display: none;
}

.loadingContainer[data-loading='true'] {
  display: unset;
}

.loading {
  margin: 4rem 0;
}

.errorContainer {
  display: none;
}

.errorContainer[data-error='true'] {
  display: unset;
}

.error {
  display: flex;
  justify-content: center;
  margin: 4rem 0;
}

.resultContainer[data-loading='true'],
.resultContainer[data-error='true'] {
  display: none;
}

.benefitOuterWrapper {
  display: flex;
}

.benefitWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex: 0.75 0 0;
}

.policyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 30px;
  margin-bottom: 0.5rem;
  align-items: center;
}

.flexBox {
  display: flex;
}

.adminText {
  margin-left: 32px;
  color: black;
}

.outerConatiner {
  margin-bottom: 4rem;
}

.stackY32 {
  margin-bottom: 2rem;
}

.stackY64 {
  margin-bottom: 4rem;
}

import { useEffect } from 'react'

export const useAutoScrollTop = () => {
  useEffect(() => {
    if (window.scrollY > 0) {
      requestAnimationFrame(() => {
        window.scrollTo({ top: 0 })
      })
    }
  }, [])
}

import type { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { MainLayout } from './components/MainLayout/MainLayout'
import { BookOfBusiness, BookOfBusinessTabKey } from './pages/BookOfBusiness'
import { Error } from './pages/Error/Error'
import { Logout } from './pages/Logout'
import { ViewSponsor } from './pages/ViewSponsor'

// TODO add route paths to a typescript string union or enum

export const AppRoutes: FC = () => (
  <Routes>
    <Route element={<MainLayout />}>
      {/* Book of bussiness pages */}
      <Route path="/all-clients" element={<BookOfBusiness tab={BookOfBusinessTabKey.AllClients} />} />
      <Route path="/recently-viewed" element={<BookOfBusiness tab={BookOfBusinessTabKey.RecentlyViewed} />} />
      <Route path="/starred" element={<BookOfBusiness tab={BookOfBusinessTabKey.Starred} />} />
      <Route path="/terminated" element={<BookOfBusiness tab={BookOfBusinessTabKey.Terminated} />} />
      {/* Sponsor pages */}
      <Route path="/sponsor/:sponsorId/*" element={<ViewSponsor />} />
      {/* Logout pages */}
      <Route path="/logout" element={<Logout />} />
      {/* Not found page */}
      <Route path="*" element={<Error type="generic" />} />

      <Route path="/" element={<Navigate replace to="/all-clients" />} />
    </Route>
  </Routes>
)

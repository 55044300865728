import { statusToMessage } from '../BenefitCard/Status'

export const Glossary = () => {
  return (
    <>
      <p>
        <strong>In process</strong>: {statusToMessage['IN PROCESS']}
      </p>
      <p>
        <strong>Risk approved</strong>: {statusToMessage['RISK APPROVED']}
      </p>
      <p>
        <strong>In force</strong>: {statusToMessage['IN FORCE']}
      </p>
      <p>
        <strong>Terminated</strong>: {statusToMessage['TERMINATED']}
      </p>
    </>
  )
}

import { Link } from '@component-library/helios'
import { useContext, type FC } from 'react'

import { useDownloadSponsors } from '../../api/hooks/useDownloadSponsors'
import { BookOfBusinessFilterContext } from '../../context/BookOfBusinessFilterContext'
import { useAuth } from '../../hooks/useAuth'

interface BookOfBusinessDownloadButtonProps {
  text: string
  disabled: boolean
  fileType: string
  onClick?: () => void
}

export const BookOfBusinessDownloadButton: FC<BookOfBusinessDownloadButtonProps> = ({
  disabled,
  fileType,
  text,
  onClick,
}: BookOfBusinessDownloadButtonProps) => {
  const { user } = useAuth()
  const filter = useContext(BookOfBusinessFilterContext)
  const download = useDownloadSponsors()

  const handleDownloadClick: React.MouseEventHandler = (e) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    onClick?.()
    download({
      query: {
        ...filter,
        fileType,
        brokerID: user?.brokerID,
        payToNumbers: JSON.stringify(user?.payToNumbers),
        username: user?.username,
      },
    })
  }

  return (
    <Link href="#" onClick={handleDownloadClick} disabled={disabled}>
      {text}
    </Link>
  )
}

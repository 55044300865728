.logoutSection {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.centerText {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

import { Body, Link } from '@component-library/helios'
import { useQueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'

import { useRecentlyViewedSponsorStore } from '../../hooks/store/useRecentlyViewedSponsorStore'
import type { SponsorSortField } from '../../hooks/useBookOfBusinessPagination'
import { trackEvent } from '../../utils/analytics'
import { formatDateFullMonthWithYear } from '../../utils/format'
import { filterPolicyValidForSunLifeConnect, getDentalPolicyFromPolicies } from '../../utils/policy'
import type { SponsorId } from '../../utils/recentlyViewedSponsor'
import { PaginatedTable } from '../PaginatedTable/PaginatedTable'
import { SponsorActionsMenu } from '../SponsorActionsMenu/SponsorActionsMenu'

import styles from './BookOfBusinessTable.module.css'
import { StarIcon } from './StarIcon'

export type TableType = 'all' | 'recentlyViewed' | 'starred' | 'terminated'

const sponsorSortFieldMap: Record<string, SponsorSortField> = {
  sponsorName: 'name',
  sponsor_name: 'name',
  renewalDate: 'date_next_renew',
  next_renewal_date: 'date_next_renew',
  terminatedDate: 'date_next_renew',
}

type BookOfBusinessTableProps = {
  type: TableType
  sponsors: Sponsor[] | undefined
  emptyText?: ReactNode
  onSortChange?: (field: SponsorSortField, direction: SortDirection) => void
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  totalItems: number
  page: string
  pageSize: string
}

export const BookOfBusinessTable = ({
  type,
  sponsors,
  emptyText,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  totalItems,
  page,
  pageSize,
}: BookOfBusinessTableProps) => {
  const queryClient = useQueryClient()
  const [recentlyViewedSponsorEntries] = useRecentlyViewedSponsorStore()

  function getViewedAt(sponsorId: SponsorId) {
    return recentlyViewedSponsorEntries.find((entry) => entry.sponsorId === sponsorId)?.viewedAt
  }

  const getDateColumnByType = (type: TableType) => {
    switch (type) {
      case 'all':
      case 'starred':
        return {
          key: 'renewalDate',
          title: 'Next renewal',
          render: (sponsor: Sponsor) =>
            sponsor.nextRenewalDate ? formatDateFullMonthWithYear(new Date(sponsor.nextRenewalDate)) : '',
        }
      case 'recentlyViewed':
        return {
          key: 'lastViewed',
          title: 'Last viewed',
          render: (sponsor: Sponsor) => formatDateFullMonthWithYear(new Date(getViewedAt(sponsor.sponsorId) ?? 0)),
        }
      case 'terminated':
        return null
    }
  }

  const dateColumn = getDateColumnByType(type)

  const handleStarClick = (_id: string, starred: boolean) => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:favorite icon:status=${starred ? 'checked' : 'unchecked'}`,
    })

    if (type === 'starred') {
      queryClient.invalidateQueries({ queryKey: ['sponsor', 'favorited'] })
    } else {
      queryClient.removeQueries({ queryKey: ['sponsor', 'favorited'] })
    }
  }

  const handleSortChange = (field: string, direction: SortDirection) => {
    onSortChange?.(sponsorSortFieldMap[field], direction)
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:sort:category=${field}:sort order=${direction}`,
    })
  }

  const handlePageChange: typeof onPageChange = (page) => {
    onPageChange?.(page)
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  const initialPagination = {
    page: Number(page),
    pageSize: Number(pageSize),
  }

  return (
    <div>
      <PaginatedTable
        table={{
          columns: [
            {
              field: 'sponsorName',
              title: 'Client name',
              align: 'left',
              sortable: true,
            },
            {
              field: 'benefits',
              title: 'Benefits',
              align: 'left',
            },
            ...(dateColumn
              ? [
                  {
                    field: dateColumn.key,
                    title: dateColumn.title,
                    align: 'left' as 'left' | 'right' | 'center', // type annotation because the spread operator screws up typescript?
                    sortable: type !== 'recentlyViewed', // TODO: Remove this when we have a way to sort by last viewed date. Right now it breaks the app.
                  },
                ]
              : []),
            {
              field: 'actions',
              title: 'Actions',
              align: 'left',
              width: '1px',
            },
          ],
          rows:
            sponsors?.map((sponsor) => ({
              sponsorName: (
                <div className={styles.clientName}>
                  <Link as={ReactRouterDomLink} to={`/sponsor/${sponsor.sponsorId}`} state={{ type }} size="sm">
                    {sponsor.sponsorName}
                  </Link>
                  <br />
                  <Body variant="small" color="caption">
                    Policy {sponsor.policies.map((policy) => `#${policy.policyNumber}`).join(', ')}
                  </Body>
                </div>
              ),
              benefits: sponsor.benefits?.join(', '),
              ...(dateColumn ? { [dateColumn.key]: dateColumn.render(sponsor) } : {}),
              actions: (
                // TODO: Each row/cell should has a key prop, this should be handled from the underlying component.
                // A key is needed here, otherwise that cell doesn't change on table sort.
                <div className={styles.actionCell} key={`sponsor-${sponsor.sponsorId}-actions`}>
                  <div>
                    <StarIcon
                      className={styles.star}
                      sponsorId={sponsor.sponsorId}
                      onToggle={(star) => handleStarClick(sponsor.sponsorId.toString(), star)}
                    />
                  </div>
                  {/* Refactor this component to accept `sponsor` instead. */}
                  <SponsorActionsMenu
                    type="icon"
                    sponsorId={sponsor.sponsorId}
                    showViewSponsor={true}
                    menuItemClickTrackTitle="book of business table:button:three dot action menu"
                    benefits={sponsor.benefits}
                    hasStopLoss={sponsor.policies.map((policy) => policy.hasStopLoss).includes(true)}
                    hasHealthNavigator={sponsor.policies.map((policy) => policy.hasHealthNavigator).includes(true)}
                    hasDentalBenefit={sponsor.policies.some((policy) => policy.hasDental)}
                    dentalPolicy={getDentalPolicyFromPolicies(sponsor.policies)}
                    policyNumbers={filterPolicyValidForSunLifeConnect(sponsor.policies).map(
                      (policy) => policy.policyNumber,
                    )}
                    activeSponsor={sponsor.policies.some(
                      (policy) => policy.policyStatus.toUpperCase() !== 'TERMINATED',
                    )}
                  />
                </div>
              ),
            })) ?? [],
        }}
        emptyText={emptyText}
        totalItems={totalItems}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        initialPagination={initialPagination}
      />
    </div>
  )
}

export const billingTypeMap: Record<string, string> = {
  HOMEOFC: 'List Bill',
  EBILL: 'List Bill',
  SELFADMIN: 'Self Admin',
  HOME_OFFICE: 'List Bill',
  LIST_BILL: 'List Bill',
  SELF_ADMIN: 'Self Admin',
  SELF_ADMIN_PLUS: 'Self Admin+',
  THIRD_PARTY_ADMINISTRATOR_BILL: 'Third Party Administrator Bill',
  THIRD_PARTY_ADMINISTRATOR_PLUS_BILL: 'Third Party Administrator Bill+',
  ELECTRONIC_BILL: 'List Bill',
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  flex-grow: 1;
}

.helpWrapper {
  padding-top: 3rem;
}

.removeMargins {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

import type { FeatureToggleName } from '../api/featureToggle'

export const queryKeys = {
  /*
   * Auth
   */
  login() {
    return ['auth', 'login'] as const
  },
  extend(request?: Auth_ExtendRequest) {
    return ['auth', 'extend', request] as const
  },
  logout(request?: Auth_LogoutRequest) {
    return ['auth', 'logout', request] as const
  },

  /*
   * Sponsor
   */

  getSponsorById(request?: Partial<Sponsor_GetByIDRequestPathParams>) {
    return ['sponsor', request] as const
  },
  getSponsors(request?: Sponsor_ListRequestQueryParams) {
    return ['sponsor', request] as const
  },

  /*
   * Sponsor
   */

  getActiveSponsors(request?: Sponsor_ListRequestQueryParams) {
    return ['sponsor', 'active', request] as const
  },
  getRecentlyViewedSponsors(request?: Sponsor_ListRequestQueryParams) {
    return ['sponsor', 'recentlyViewed', request] as const
  },
  getFavoritedSponsors(request?: Sponsor_ListRequestQueryParams) {
    return ['sponsor', 'favorited', request] as const
  },
  getTerminatedSponsors(request?: Sponsor_ListRequestQueryParams) {
    return ['sponsor', 'terminated', request] as const
  },

  /*
   * Favorite
   */

  getFavoriteSponsorIds(request?: Favorite_ListFavoriteRequestPathParams) {
    return ['favorite', request?.brokerId] as const
  },
  toggleFavoriteSponsor(request?: Partial<Favorite_GetFavoriteRequestPathParams>) {
    return ['favorite', request?.brokerId] as const
  },

  /*
   * Commission
   */

  getCommissions(request?: Commissions_ListRequestQueryParams) {
    return ['commission', request] as const
  },

  /*
   * Action menus
   */

  getActionMenus(request?: Partial<Sponsor_ActionMenuRequest>) {
    return ['actionmenus', request?.sponsorId, request] as const
  },

  /*
   * Feature toggle
   */

  getFeatureToggleByName(name: FeatureToggleName) {
    return ['featuretoggle', name] as const
  },

  /*
   * Siteminder
   */

  poll(request?: Siteminder_PollRequest) {
    return ['siteminder', 'poll', request] as const
  },
} as const

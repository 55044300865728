/**
 * Checks if there is a next page based on the current page, page size, and total number of items.
 *
 * @param currentPage - The current page number.
 * @param pageSize - The number of items per page.
 * @param total - The total number of items.
 */
export function hasNextPage(currentPage: number, pageSize: number, total: number): boolean {
  return currentPage * pageSize < total
}

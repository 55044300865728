import { DateTime } from 'luxon'

export type SponsorId = Sponsor['sponsorId']

const MAX_SPONSORS = 50
const MAX_DAYS = 30
const THIRTY_DAYS_AGO = DateTime.now().minus({ days: MAX_DAYS }).toJSDate().getTime()

export type RecentViewedSponsorEntry = {
  sponsorId: SponsorId
  viewedAt: number
}

export type RecentViewedSponsorEntries = Array<RecentViewedSponsorEntry>

export const addOrUpdateRecentlyViewedSponsor = (
  entries: RecentViewedSponsorEntries,
  sponsorId: SponsorId,
): RecentViewedSponsorEntries => {
  // If sponsor is already in the list, remove it.
  entries = entries.filter((entry) => entry.sponsorId !== sponsorId)
  // Store only the max allowed sponsor.
  entries = entries.length >= MAX_SPONSORS ? entries.slice(0, MAX_SPONSORS - 1) : entries
  // Append the new sponsor.
  return [{ sponsorId: sponsorId, viewedAt: Date.now() }, ...entries]
}

interface RecentlyViewedFilterReturn {
  filteredEntries: RecentViewedSponsorEntries
  mappedSponsors: Sponsor[]
}
/**
 * This maps the local storage to API response for recently viewed sponsors.
 * It filters out the entries from local storage that do not correspond to the data.
 * It also filters out the records that are 30 days since last viewed.
 * @param {RecentViewedSponsorEntries} recentEntries the recently viewed sponsors in local storage
 * @param {Sponsor[]} sponsors the sponsors data from API response
 * @returns {RecentlyViewedFilterReturn} the filtered array of recently viewed sponsors and the mapped sponsors
 */
export const filterRecentlyViewedEntries = (
  recentEntries: RecentViewedSponsorEntries,
  sponsors: Sponsor[],
): RecentlyViewedFilterReturn => {
  const mappedSponsors: Sponsor[] = []

  const filteredEntries = recentEntries.filter((entry) => {
    const matchingSponsor = sponsors.find((s) => s.sponsorId === entry.sponsorId)
    const isLessThan30DaysAgo = entry.viewedAt > THIRTY_DAYS_AGO

    if (!!matchingSponsor && isLessThan30DaysAgo) {
      mappedSponsors.push(matchingSponsor)
      return true // keep this recently viewed entry
    }
    return false // remove this recently viewed entry
  })

  return { filteredEntries, mappedSponsors }
}

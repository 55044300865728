import { Button } from '@component-library/helios'
import { useState } from 'react'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { useAuth } from '../../hooks/useAuth'
import type { FeedbackModalForm } from '../FeedbackModal/FeedbackModal'
import { FeedbackModal } from '../FeedbackModal/FeedbackModal'

import styles from './FeedbackButton.module.css'

export const FeedbackButton = () => {
  const ft = useGetFeatureToggleByName('BEX_SEGMENT_FEEDBACK')
  const auth = useAuth()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => setIsModalVisible(true)
  const hideModal = () => setIsModalVisible(false)
  const submitForm = async (form: FeedbackModalForm) => {
    analytics.track('Feedback Submitted', {
      username: auth.user?.username,
      message: form.message,
      allowContact: form.allowContact,
    })
    hideModal()
  }

  return (
    auth.user &&
    ft.data?.enabled && (
      <div className={styles.container}>
        {!isModalVisible && (
          <Button variant="fab" onClick={showModal} icon="far fa-bell" iconPosition="before">
            Feedback
          </Button>
        )}
        <FeedbackModal show={isModalVisible} onHide={hideModal} onSubmit={submitForm} />
      </div>
    )
  )
}

.errorContainer {
  padding: 5rem 0 10rem;
  text-align: left;
}

.errorContainer :global(ol) {
  display: inline-block;
}

.errorContainer :global(li) {
  text-align: left;
}

.errorSpacing {
  margin-bottom: 1rem;
}

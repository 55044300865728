import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'

import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import type { BookOfBusinessFilter } from '../../context/BookOfBusinessFilterContext'
import { BookOfBusinessFilterContext } from '../../context/BookOfBusinessFilterContext'
import { useRecentlyViewedSponsorStore } from '../../hooks/store/useRecentlyViewedSponsorStore'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { useRecentlyViewedSponsors } from '../../hooks/useRecentlyViewedSponsors'
import { trackEvent } from '../../utils/analytics'
import { BenefitStatus, filterSponsorsBenefitsByBenefitStatus } from '../../utils/benefit'
import { formatDate } from '../../utils/format'
import type { SponsorId } from '../../utils/recentlyViewedSponsor'

import { useEmptyText } from './useEmptyText'

export const RecentlyViewedSponsors: FC = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounced(searchQuery, 500)
  const [recentlyViewedSponsorEntries] = useRecentlyViewedSponsorStore()
  const query = useRecentlyViewedSponsors(
    {
      query: {
        q: debouncedSearchQuery,
        ...sort,
        ...pagination,
      },
    },
    { prefetchNextPage: true },
  )
  const emptyText = useEmptyText(
    debouncedSearchQuery,
    "You haven't viewed any clients in the past 30 days. When you view a client, it will appear here.",
  )

  const getViewedAt = useCallback(
    (sponsorId: SponsorId) => {
      return recentlyViewedSponsorEntries.find((entry) => entry.sponsorId === sponsorId)?.viewedAt
    },
    [recentlyViewedSponsorEntries],
  )

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query)
  }

  const filter: BookOfBusinessFilter = {
    ...sort,
    sponsorIds: recentlyViewedSponsorEntries.map(({ sponsorId }) => sponsorId).join(','),
    recentlyViewedDates: recentlyViewedSponsorEntries
      .map(({ sponsorId }) => formatDate(new Date(getViewedAt(sponsorId) ?? 0)))
      .join(','),
  }

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchQueryChange() {
      if (searchQuery.length > 0 && searchQuery === debouncedSearchQuery) onPageChange(1)
    },
    [debouncedSearchQuery, onPageChange, searchQuery],
  )

  useEffect(() => {
    if (debouncedSearchQuery) {
      analytics.track('book of business table:search recently viewed employers', {
        query: debouncedSearchQuery,
      })
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    const recentlyViewedSponsors = query.data?.bookOfBusiness?.sponsors ?? []

    if (debouncedSearchQuery.length > 0 && recentlyViewedSponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty recently viewed employers query`,
      })
  }, [debouncedSearchQuery, query.data?.bookOfBusiness?.sponsors])

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download recently viewed employers',
    })
  }

  const recentlyViewedSponsors = query.data?.bookOfBusiness?.sponsors ?? []

  return (
    <BookOfBusinessFilterContext.Provider value={filter}>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.recentlyViewed}
        downloadButtonProps={{
          disabled: recentlyViewedSponsors.length === 0,
          text: 'Download recently viewed clients',
          fileType: 'recently viewed',
          onDownloadClick: () => handleDownloadClick(),
        }}
        label="Search in recently viewed..."
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
      />
      <QueryResultUnconditional query={query}>
        {() => {
          const sponsors = filterSponsorsBenefitsByBenefitStatus(recentlyViewedSponsors, [BenefitStatus.RISK_APPROVED])
          const sponsorsTotal = query.data?.bookOfBusiness?.totalRecords ?? 0

          return (
            <BookOfBusinessTable
              type="recentlyViewed"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={sponsorsTotal}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </BookOfBusinessFilterContext.Provider>
  )
}

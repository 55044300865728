import type { QueryClientConfig } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/react-query'
import merge from 'lodash/merge'

export const getQueryClient = (options: QueryClientConfig = {}) => {
  const defaults: QueryClientConfig = {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        retry: 2,
        retryDelay: (attempt) => attempt * 1000,
      },
    },
  }
  return new QueryClient(merge(defaults, options))
}

import { isLocal } from './getClientConfig'

/**
 * @see https://docs.tealium.com/platforms/javascript/single-page-applications/#track-views
 */
export const trackView = (data: Record<string, string | string[]>) => {
  if (!window.utag) {
    return console.warn('utag is not defined')
  }

  if (isLocal) {
    return console.debug('skipping utag.view because current is local', data)
  }

  window.utag.view(data)
}

/**
 * @see https://docs.tealium.com/platforms/javascript/single-page-applications/#track-events
 */
export const trackEvent = (data: Record<string, string | string[]>) => {
  if (!window.utag) {
    return console.warn('utag is not defined')
  }

  if (isLocal) {
    return console.debug('skipping utag.link because current is local', data)
  }

  window.utag.link(data)
}

import { Modal, TextArea, HelperText, Radio, Button, Link } from '@component-library/helios'
import classNames from 'classnames'
import type { FormEventHandler, BaseSyntheticEvent } from 'react'
import { useEffect, useState } from 'react'

import styles from './FeedbackModal.module.css'

export type FeedbackModalForm = {
  message: string
  allowContact?: boolean
}

type FeedbackModalFormError = Partial<Record<keyof FeedbackModalForm, boolean>> & {
  unknown?: boolean
}

type FeedbackModalProps = {
  show: boolean
  onHide: () => void
  onSubmit: (form: FeedbackModalForm) => void | Promise<void>
}

export const FeedbackModal = ({ show, onHide, onSubmit }: FeedbackModalProps) => {
  const [form, setForm] = useState<FeedbackModalForm>({ message: '' })
  const [error, setError] = useState<FeedbackModalFormError>({})
  const [isBusy, setIsBusy] = useState(false)
  const handleFormSubmission: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const error: FeedbackModalFormError = {}

    if (form.message.trim().length === 0) {
      error.message = true
    }

    if (form.allowContact === undefined) {
      error.allowContact = true
    }

    const hasErrors = Object.keys(error).length > 0

    if (hasErrors) {
      setError(error)
    } else {
      try {
        setIsBusy(true)
        await onSubmit({ ...form })
        setForm({ message: '' })
      } catch (error) {
        console.error(error)
        setError({ unknown: true })
      } finally {
        setIsBusy(false)
      }
    }
  }

  useEffect(
    function resetFormStateOnShow() {
      if (show) {
        setForm({ message: '' })
        setError({})
      }
    },
    [show],
  )

  return (
    <div className={classNames(styles.container, show && styles.visible)}>
      <Modal.Dialog
        id="feedback-modal"
        role="dialog"
        aria-hidden={!show}
        heading="Give feedback"
        lang="en"
        onHide={onHide}
      >
        <Modal.Body>
          <form className={styles.body} onSubmit={handleFormSubmission}>
            <div>
              <strong>Have feedback on the new experience? We&apos;d love to hear it!</strong>
              <TextArea
                id="feedback-modal-message"
                name="message"
                helperText={undefined}
                label=""
                className={undefined}
                disabled={isBusy}
                error={error.message}
                value={form.message}
                onInput={(event: BaseSyntheticEvent) => setForm((form) => ({ ...form, message: event.target.value }))}
              />
              {error.message && (
                <HelperText id="feedback-modal-message-error" error>
                  This field is required
                </HelperText>
              )}
            </div>
            <p>
              If you have questions about managing your policies, reach out the Sun Life contacts listed on the Client
              Detail view, or explore our{' '}
              <Link
                href="https://slfus.my.site.com/learningcenteremployers/s/topic/0TO4u000000IuxWGAS/brokers-consultants"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </Link>
            </p>
            <div>
              <strong>Would you like Sun Life to contact you about your feedback?</strong>
              <div className={styles.allowContactGroup}>
                <Radio
                  id="feedback-modal-sunlife-contact-yes"
                  name="sunlifeContact"
                  label="Yes"
                  value="yes"
                  disabled={isBusy}
                  isInvalid={error.allowContact}
                  checked={form.allowContact === true}
                  onChange={(event: BaseSyntheticEvent) => setForm({ ...form, allowContact: event.target.checked })}
                />
                <Radio
                  id="feedback-modal-sunlife-contact-no"
                  name="sunlifeContact"
                  label="No"
                  value="no"
                  disabled={isBusy}
                  isInvalid={error.allowContact}
                  checked={form.allowContact === false}
                  onChange={(event: BaseSyntheticEvent) => setForm({ ...form, allowContact: !event.target.checked })}
                />
              </div>
              {error.allowContact && (
                <HelperText id="feedback-modal-textarea-error" error>
                  This field is required
                </HelperText>
              )}
            </div>
            {error.unknown && (
              <HelperText id="feedback-modal-unknown-error" error>
                An unknown error occurred. Please try again later.
              </HelperText>
            )}
            <Button variant="primary" id="action-button" type="submit" disabled={isBusy}>
              Send Feedback
            </Button>
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}

import { Modal, Button, Body } from '@component-library/helios'

export type ExtendSessionModalProps = {
  isModalOpen: boolean
  isLoading: boolean
  remainingTime: string
  onExtend: () => void
  onLogout: () => void
}

export function ExtendSessionModal({
  isModalOpen,
  isLoading,
  remainingTime,
  onExtend,
  onLogout,
}: ExtendSessionModalProps) {
  return (
    <Modal id="extendSessionModal" lang="en" heading="Your session will expire" show={isModalOpen} hideCloseButton>
      <Modal.Body>
        <Body as="p">
          Your session will expire in {remainingTime}.<br />
          <br />
          Do you want to extend your session?
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={isLoading} onClick={onLogout}>
          Logout
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={onExtend}>
          Yes/Extend
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

/* media breakpoints */
/* 
  xs: 0,
  sm: 600px,
  md: 905px,
  lg: 1240px,
  xl: 1440px,
*/

/* xs */
@media only screen and (max-width: 600px) {
  .responsiveContainer {
    padding: 0 16px;
  }
}
/* sm */
@media only screen and (min-width: 600px) and (max-width: 905px) {
  .responsiveContainer {
    padding: 0 32px;
  }
}
/* md */
@media only screen and (min-width: 905px) and (max-width: 1240px) {
  .responsiveContainer {
    padding: 0 60px;
  }
}
/* lg */
@media only screen and (min-width: 1240px) and (max-width: 1440px) {
  .responsiveContainer {
    padding: 0 44px;
  }
}
/* xl */
@media only screen and (min-width: 1440px) {
  .responsiveContainer {
    padding: 0 144px;
  }
}

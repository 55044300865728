import { useEffect, useState } from 'react'

export function useDebounced<T>(value: T, ms: number, initialValue?: T): T {
  const [debounced, setDebounced] = useState<T>(initialValue ?? value)

  useEffect(() => {
    const id = setTimeout(() => {
      setDebounced(value)
    }, ms)
    return () => clearTimeout(id)
  }, [ms, value])

  return debounced
}

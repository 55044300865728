export const createFormElement = (formElem: InputElement) => {
  const formElemInput = document.createElement('input')
  formElemInput.id = formElem.elementId
  formElemInput.name = formElem.elementName
  formElemInput.value = formElem.elementValue
  return formElemInput
}

export const deepLinktoApplication = (url: string, funcName: string, formElements: InputElement[], app: string) => {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = url
  if (app === 'slconnect') {
    const slcFunctionInput = document.createElement('input')
    slcFunctionInput.type = 'hidden'
    slcFunctionInput.name = 'func'
    slcFunctionInput.value = funcName
    form.appendChild(slcFunctionInput)
  }
  for (const fe of formElements) {
    form.append(createFormElement(fe))
  }
  document.body.appendChild(form)
  form.target = app
  window.open('', app)
  form.submit()
  document.body.removeChild(form)
}

import { Body, Divider, Heading } from '@component-library/helios'
import { captureMessage } from '@sentry/react'
import type { FC } from 'react'

import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import { trackEvent } from '../../utils/analytics'
import { filterPolicyValidForSunLifeConnect, getCommissionsText, parseComissions } from '../../utils/policy'
import { StarIcon } from '../BookOfBusinessTable/StarIcon'
import { QueryResult } from '../QueryResult/QueryResult'
import { SponsorActionsMenu } from '../SponsorActionsMenu/SponsorActionsMenu'

import { ContactInfo, ContactType } from './ContactInfo'
import styles from './SponsorInfoPane.module.css'

// TODO this interface can be refactored to match structure/field names coming from api (sponsor/{id} endpoint)
export interface Props {
  sponsor: Sponsor
  dentalPolicy?: string
  isInternalUser: boolean
}

export const SponsorInfoPane: FC<Props> = ({ sponsor, dentalPolicy, isInternalUser }) => {
  const id = sponsor.sponsorId ?? ''
  const name = sponsor.sponsorName ?? ''
  const commissions = parseComissions(sponsor.commissionsPaidYearTodate)
  const getCommissionsPaidToDateFt = useGetFeatureToggleByName('BEX_COMMISSIONS_PAID_TO_DATE')

  const handleStarClick = (_id: string, starred: boolean) => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `employer details:favorite icon:status=${starred ? 'checked' : 'unchecked'}`,
    })
  }

  const benefits = sponsor.policies.reduce<string[]>((result, policy) => {
    return result.concat(policy.coverages.map((coverage) => coverage.benefit))
  }, [])

  const hasStopLoss = sponsor.policies.filter((policy) => policy.hasStopLoss).length > 0
  const hasHealthNavigator = sponsor.policies.filter((policy) => policy.hasHealthNavigator).length > 0
  const hasGroupBenefits = sponsor.policies.filter((policy) => policy.hasGroupBenefits).length > 0
  const hasDentalBenefit = sponsor.policies.filter((policy) => policy.hasDental).length > 0

  const activeSponsor = sponsor.policies.some((policy) => policy.policyStatus.toUpperCase() !== 'TERMINATED')

  const stopLossContact = sponsor.policies.reduce<ClientManager | undefined>((result, policy) => {
    if (policy.clientManagers) result = policy.clientManagers.find((manager) => manager.ContactType === 'StopLoss')
    return result
  }, undefined)

  const healthNavigatorContact = sponsor.policies.reduce<ClientManager | undefined>((result, policy) => {
    if (policy.clientManagers)
      result = policy.clientManagers.find((manager) => manager.ContactType === 'HealthNavAssist')
    return result
  }, undefined)

  if (hasStopLoss && !stopLossContact) {
    captureMessage(`Stop Loss contact information is missing for sponsor ${sponsor.sponsorName}`)
  }

  if (hasHealthNavigator && !healthNavigatorContact) {
    captureMessage(`Health navigator contact information is missing for sponsor ${sponsor.sponsorName}`)
  }

  return (
    <QueryResult query={getCommissionsPaidToDateFt}>
      {(commissionsPaidToDateFt) => (
        <div className={styles.sponsorInfoPane}>
          <div className={styles.header}>
            <div>
              <Heading variant="1" as="h1" className={styles.sponsorName}>
                <span>{name}</span>
                {!isInternalUser && (
                  <StarIcon
                    className={styles.star}
                    sponsorId={id}
                    onToggle={(star) => handleStarClick(id.toString(), star)}
                  />
                )}
              </Heading>
              {commissionsPaidToDateFt.enabled && (
                <Body variant="medium" color="caption">
                  {getCommissionsText(commissions)}
                </Body>
              )}
            </div>
            {/* TODO: Convert to Helios design. */}
            <SponsorActionsMenu
              type="button"
              menuItemClickTrackTitle="employer details page:link:actions menu"
              sponsorId={id}
              benefits={benefits}
              dentalPolicy={dentalPolicy}
              policyNumbers={filterPolicyValidForSunLifeConnect(sponsor.policies).map((policy) => policy.policyNumber)}
              hasStopLoss={hasStopLoss}
              hasHealthNavigator={hasHealthNavigator}
              hasDentalBenefit={hasDentalBenefit}
              activeSponsor={activeSponsor}
            />
          </div>
          <Divider className={styles.divider} />
          <div className={styles.contactList}>
            {hasStopLoss && stopLossContact && (
              <ContactInfo
                name={stopLossContact.Contact.Name}
                email={stopLossContact.Contact.Email}
                contactType={ContactType.StopLoss}
              />
            )}
            {hasGroupBenefits && (
              <>
                <ContactInfo contactType={ContactType.GroupServices} name="Client services" />
                <ContactInfo contactType={ContactType.EnrollServices} name="Customer advocacy" />
              </>
            )}
            {hasHealthNavigator && healthNavigatorContact && (
              <ContactInfo
                name={healthNavigatorContact.Contact.Name}
                email={healthNavigatorContact.Contact.Email}
                contactType={ContactType.HealthNavAssist}
              />
            )}
          </div>
        </div>
      )}
    </QueryResult>
  )
}

import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { usePostExtendAuth } from '../../api/hooks/usePostExtendAuth'
import { usePostSiteminderToken } from '../../api/hooks/usePostSiteminderToken'
import { useAuth } from '../../hooks/useAuth'
import { getAuthTokenActivity } from '../../utils/getAuthTokenActivity'
import { ExtendSessionModal } from '../ExtendSessionModal/ExtendSessionModal'

export const AuthMonitor = () => {
  const { logout, authToken } = useAuth()
  const [isExtendSessionModalOpen, setIsExtendSessionModalOpen] = useState(false)
  const [activity, setActivity] = useState(getAuthTokenActivity(authToken))
  const extendAuth = usePostExtendAuth({ body: { token: authToken?.token } }, { enabled: false })
  const siteminderPoll = usePostSiteminderToken({}, { enabled: false })
  const idleTimer = useIdleTimer({
    crossTab: true,
    timeout: 2 * 60 * 1000, // 2 minutes.
  })

  const { refetch: refetchExtendAuth } = extendAuth

  // Update token activity.
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActivity(getAuthTokenActivity(authToken))
    }, 1000) // Each second, to be able to render the remaining time in a timely fashion.

    return () => {
      clearInterval(intervalId)
    }
  }, [authToken])

  // Update siteminder token activity for every 30 seconds.
  // ToDo: remove disabled eslint line and add the dependency to useEffect
  useEffect(() => {
    const siteMinderIntervalId = setInterval(() => {
      siteminderPoll.refetch()
    }, 40000)

    return () => {
      clearInterval(siteMinderIntervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  // Handle session activity
  const isIdle = idleTimer.isIdle()
  useEffect(() => {
    // Close the modal if the re-authorization fails.
    if (extendAuth.isError) {
      setIsExtendSessionModalOpen(false)
    } else if (activity.status === 'will-expire' && authToken?.token && !isExtendSessionModalOpen) {
      if (isIdle) {
        setIsExtendSessionModalOpen(true)
      } else if (!extendAuth.isLoading) {
        refetchExtendAuth()
      }
    } else if (activity.status === 'active' && isExtendSessionModalOpen) {
      setIsExtendSessionModalOpen(false)
    } else if (activity.status === 'expired') {
      logout()
    }
  }, [
    activity.status,
    isExtendSessionModalOpen,
    logout,
    authToken?.token,
    extendAuth.isError,
    isIdle,
    refetchExtendAuth,
    extendAuth.isLoading,
  ])

  // Handle the re-authorization (session extension).
  useEffect(() => {
    if (extendAuth.isSuccess) {
      const { token, createdAt } = extendAuth.data.user
      const newToken = { token, createdAt }
      setActivity(getAuthTokenActivity(newToken))
    }
  }, [extendAuth.data?.user, extendAuth.isSuccess])

  const handleExtendSession = () => {
    refetchExtendAuth()
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <ExtendSessionModal
      isModalOpen={isExtendSessionModalOpen}
      isLoading={extendAuth.isLoading}
      remainingTime={activity.remainingTime}
      onExtend={handleExtendSession}
      onLogout={handleLogout}
    />
  )
}

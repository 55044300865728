import { Body } from '@component-library/helios'
import { type ReactNode } from 'react'

import styles from './EmptyState.module.css'

export interface EmptyStateProps {
  emptyText?: ReactNode
}

export const EmptyState = ({ emptyText }: EmptyStateProps) => {
  if (emptyText == null) return

  return (
    <div className={styles.emptyState}>
      <Body>{emptyText}</Body>
    </div>
  )
}

import { DateTime, FixedOffsetZone } from 'luxon'

const uiDateWithYear = 'LLLL d, y'

/**
 * @param input
 * @returns e.g. October 31, 2021
 */
export const formatDateFullMonthWithYear = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat(uiDateWithYear)

/**
 * @param input
 * @returns e.g. 10/30/2021 in UTC timezone
 */
export const formatDate = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toLocaleString(DateTime.DATE_SHORT)

export type ListFormatOptions = {
  type: 'conjunction' | 'disjunction' | 'unit'
  style: 'long' | 'short' | 'narrow'
}

const defaultListFormat: ListFormatOptions = {
  type: 'conjunction',
  style: 'long',
}

export function listFormat(wordArray: string[], options: ListFormatOptions = defaultListFormat) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ListFormat is not currently exported because Microsoft is slacking
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- see above
  return new Intl.ListFormat('en-US', options).format(wordArray)
}

import { useEffect } from 'react'

import { useAuth } from '../../hooks/useAuth'

declare global {
  interface Window {
    Appcues?: {
      identify(userId: string, attributes?: Record<string, unknown>): void
      group(groupId: string, attributes?: Record<string, unknown>): void
    }
    AppcuesSettings?: Record<string, unknown>
  }
}

export const AppcuesIdentifier = () => {
  const auth = useAuth()

  useEffect(() => {
    if (window.Appcues && auth.user) {
      window.Appcues.identify(auth.user.id, { ...auth.user })
    }
  }, [auth.user])

  return null
}

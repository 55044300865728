import { QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from './AppRoutes'
import { getQueryClient } from './api/queryClient'
import { AppcuesIdentifier } from './components/AppcuesIdentifier/AppcuesIdentifier'
import { AuthProvider } from './components/AuthProvider/AuthProvider'
import { FeedbackButton } from './components/FeedbackButton/FeedbackButton'
import { ViewTracker } from './components/ViewTracker/ViewTracker'
import { useErrorBoundary } from './hooks/useErrorBoundary'
import { ErrorBoundary } from './pages/ErrorBoundary/ErrorBoundary'

import './App.module.css'

// TODO find out all variations of authentication errors and add them to condition
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAuthenticationError = (err: any): boolean =>
  err?.response?.data?.error === 'unable to authenticate: logged off: ErrInternal' || err?.response?.status === '401'

const QueryClient: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setError } = useErrorBoundary()

  const queryClient = getQueryClient({
    queryCache: new QueryCache({
      // TODO Figure out typing for this Axios / react-query error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        // don't throw error when authentication error
        if (!isAuthenticationError(err)) setError(true)
      },
    }),
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  )
}

export const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <QueryClient>
          <AuthProvider>
            <ViewTracker />
            <AppcuesIdentifier />
            <FeedbackButton />
            <AppRoutes />
          </AuthProvider>
        </QueryClient>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

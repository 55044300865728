import { Header } from '@component-library/helios'
import classNames from 'classnames'
import FocusTrap from 'focus-trap-react'
import type { BaseSyntheticEvent, ComponentProps, FC } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { useExpandCollapseTransitionReducer } from '../../hooks/useExpandCollapseTransitionReducer'
import { getBookOfBusinessTabByPathname } from '../../pages/BookOfBusiness'
import { trackEvent } from '../../utils/analytics'
import { getConfigForCommonloginUrl, getConfigForOABrokerUrl } from '../../utils/getClientConfig'
import type { Auth } from '../AuthContext/AuthContext'
import { HelpContent } from '../HelpContent/HelpContent'
import { PageContent } from '../PageContent/PageContent'

import styles from './TopNav.module.css'

export const TopNav: FC = () => {
  const { pathname } = useLocation()
  const auth = useAuth()

  const { transitionState: supportBannerTransitionState, setTransitionState: setSupportBannerTransitionState } =
    useExpandCollapseTransitionReducer()
  const supportInteractible =
    supportBannerTransitionState === 'expanding' || supportBannerTransitionState === 'expanded'

  const utilityNavItems: ComponentProps<typeof Header>['utilityNavItems'] = [
    {
      href: '#',
      id: 'get-help',
      showIcon: {
        faClass: 'fal fa-question-circle',
        md: true,
        lg: true,
      },
      label: 'Get help',
      type: '',
      onClick: () => handleGetHelpClick(),
    },
  ]

  if (auth.user) {
    const accountItems = getAccountMenuItems(auth)

    utilityNavItems.push({
      id: 'account',
      type: 'ACCOUNT',
      label: [auth.user?.firstName, auth.user?.lastName].join(' '),
      href: '#',
      children: accountItems,
    })
  }

  const handleGetHelpClick = () => {
    if (supportBannerTransitionState === 'collapsed')
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: 'header link: Get Help',
      })
    setSupportBannerTransitionState('toggle')
  }

  return (
    <>
      <Header
        logos={[
          {
            href: '/',
            imageAlt: 'Sun Life logo',
            imageSrc: '/public/images/SL_CLR_RGB_White_Background.jpg',
            type: 'LEFT',
          },
          {
            href: '/',
            imageAlt: 'Sun Life logo',
            imageSrc: '/public/images/SL_CLR_RGB_White_Background.jpg',
            type: 'MOBILE',
          },
        ]}
        mainNavItems={getTopNavItems(auth.user, pathname)}
        menuCloseButtonLabel="Close menu"
        menuToggleButtonLabel="Open menu"
        skipLinks={[
          {
            href: '#',
            label: 'skip to content',
          },
          {
            href: '#',
            label: 'skip to footer',
          },
        ]}
        utilityNavItems={utilityNavItems}
      />

      <FocusTrap active={supportInteractible} focusTrapOptions={{ escapeDeactivates: false, allowOutsideClick: true }}>
        <div>
          <div
            className={classNames(
              styles.support,
              supportBannerTransitionState === 'collapsed' && styles.collapsed,
              supportBannerTransitionState === 'expanding' && styles.expanding,
              supportBannerTransitionState === 'expanded' && styles.expanded,
            )}
            aria-hidden={!supportInteractible}
            onTransitionEnd={(e) => {
              if (e.target === e.currentTarget) {
                setSupportBannerTransitionState('end')
              }
            }}
          >
            <div>
              <PageContent>
                <HelpContent />
              </PageContent>
            </div>
          </div>

          <div
            className={classNames(
              styles.mask,
              supportBannerTransitionState === 'collapsed' && styles.collapsed,
              supportBannerTransitionState === 'expanding' && styles.expanding,
              supportBannerTransitionState === 'expanded' && styles.expanded,
            )}
            onClick={() => setSupportBannerTransitionState('toggle')}
            onKeyUp={() => setSupportBannerTransitionState('toggle')}
            role="button"
            aria-label="Close support information"
            tabIndex={-1}
          />
        </div>
      </FocusTrap>
    </>
  )
}

function getAccountMenuItems(auth: Auth) {
  const commonLoginUrl = getConfigForCommonloginUrl()
  const oaBrokerURL = getConfigForOABrokerUrl()
  const accountMenutItems = []

  accountMenutItems.push({
    label: 'My profile',
    description: 'Manage user name, password, and more',
    onClick: () => openInNewTab(`${commonLoginUrl}myprofile`, 'commonlogin'),
  })

  if (auth.user?.permissions.viewCommissions) {
    accountMenutItems.push({
      label: 'My account',
      description: 'Update tax ID, pay to numbers, and more',
      onClick: () => openInNewTab(`${oaBrokerURL}oabroker/Broker.jsp#ManageUserAccount:edit`, 'oabroker'),
    })
  }

  accountMenutItems.push({
    label: 'Log out',
    onClick: () => auth.logout(),
  })

  return accountMenutItems
}

function getTopNavItems(user: User | undefined, pathname: string) {
  const oaBrokerURL = getConfigForOABrokerUrl()

  if (!user) return []

  const topNavItems = []

  if (!user.permissions.isInternalUser) {
    topNavItems.push({
      active: false,
      href: '/all-clients',
      label: 'Book of business',
    })
  }

  if (user.permissions.viewCommissions) {
    topNavItems.push({
      active: false,
      href: '#1',
      label: 'Commissions',
      onClick: (event: BaseSyntheticEvent) => {
        const expanded = event.currentTarget.classList.contains('show')
        if (!expanded)
          trackEvent({
            ev_type: 'other',
            ev_action: 'clk',
            ev_title: 'header link: commissions',
          })
      },
      menu: [
        {
          label: 'Commissions statements',
          onClick: () => openInNewTab(`${oaBrokerURL}oabroker/Broker.jsp#Commissions:statements`, 'oabroker'),
        },
        {
          label: 'Monthly commissions report',
          onClick: () => openInNewTab(`${oaBrokerURL}oabroker/Broker.jsp#MonthlyCommissionReport:view`, 'oabroker'),
        },
        {
          label: 'Direct deposit',
          onClick: () => openInNewTab(`${oaBrokerURL}oabroker/Broker.jsp#DirectDeposit:edit`, 'oabroker'),
        },
      ],
    })
  }

  return topNavItems
}

function openInNewTab(externalURL: string, appName: string) {
  let key = externalURL
  if (externalURL.toLowerCase().includes('myprofile')) key = 'My profile'
  else if (externalURL.toLowerCase().includes('manageuserAccount')) key = 'My account'
  else if (externalURL.toLowerCase().includes('directdeposit')) key = 'Commissions-DirectDeposit'

  trackEvent({
    ev_type: 'other',
    ev_action: 'clk',
    ev_title: `${appName}:${key}`,
  })
  window.open(externalURL, appName)
}

import { Body, Link } from '@component-library/helios'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FC, ReactElement, ReactNode } from 'react'

import styles from './ActionMenuError.module.css'

type ActionMenuErrorProps = {
  message: ReactNode
  email?: string
  phone?: string
  emailDisplayName?: string
}

export const ActionMenuError: FC<ActionMenuErrorProps> = (props): ReactElement => {
  const { message, email, phone, emailDisplayName } = props

  return (
    <div className={styles.outerContainer}>
      <div className={styles.errorMessage}>{message}</div>
      {email && (
        <Body variant="medium-bold" as="div" className={styles.contactInfoInnerContainer}>
          <Link href={`mailto:${email}`} title={email}>
            <FontAwesomeIcon icon={faEnvelope} /> {emailDisplayName}
          </Link>
        </Body>
      )}
      {phone && (
        <Body variant="medium" as="div" className={styles.contactInfoInnerContainer}>
          <Link href={`tel:${phone}`}>
            <FontAwesomeIcon icon={faPhone} /> {phone}
          </Link>
        </Body>
      )}
    </div>
  )
}

// GENERATED CODE, DO NOT TOUCH (from client/app/api/endpoints.tpl)
// This is automatically generated when files are modified in dev mode.

import { createEndpointUtils } from "./axios";

export const endpoints = {
  Health_Health: createEndpointUtils<never, never, never, Health_HealthResponse
  >('GET', '/api/v1/health/'),

  Sponsor_List: createEndpointUtils<never, Sponsor_ListRequestQueryParams, never, Sponsor_ListResponse
  >('GET', '/api/v1/sponsor/'),

  Sponsor_Download: createEndpointUtils<never, Sponsor_DownloadRequestQueryParams, never, Sponsor_DownloadResponse
  >('GET', '/api/v1/sponsor/download'),

  Sponsor_GetByID: createEndpointUtils<Partial<Sponsor_GetByIDRequestPathParams>, never, never, Sponsor_GetByIDResponse
  >('GET', '/api/v1/sponsor/{id}'),

  Sponsor_ActionMenu: createEndpointUtils<never, never, Sponsor_ActionMenuRequest, Sponsor_ActionMenuResponse
  >('POST', '/api/v1/sponsor/actionsmenu'),

  Commissions_List: createEndpointUtils<never, Commissions_ListRequestQueryParams, never, Commissions_ListResponse
  >('GET', '/api/v1/commissions/'),

  Auth_Auth: createEndpointUtils<never, never, Auth_AuthRequest, Auth_AuthResponse
  >('POST', '/api/v1/auth/auth'),

  Auth_Logout: createEndpointUtils<never, never, Auth_LogoutRequest, Auth_LogoutResponse
  >('POST', '/api/v1/auth/logout'),

  Auth_Extend: createEndpointUtils<never, never, Auth_ExtendRequest, Auth_ExtendResponse
  >('POST', '/api/v1/auth/extend'),

  Favorite_ListFavorite: createEndpointUtils<Partial<Favorite_ListFavoriteRequestPathParams>, never, never, Favorite_ListFavoriteResponse
  >('GET', '/api/v1/favorite/{brokerId}'),

  Favorite_GetFavorite: createEndpointUtils<Partial<Favorite_GetFavoriteRequestPathParams>, never, never, Favorite_GetFavoriteResponse
  >('GET', '/api/v1/favorite/{sponsorId}/{brokerId}'),

  FeatureToggle_Get: createEndpointUtils<Partial<FeatureToggle_GetRequestPathParams>, never, never, FeatureToggle_GetResponse
  >('GET', '/api/v1/featuretoggle/{name}'),

  Siteminder_Poll: createEndpointUtils<never, never, never, Siteminder_PollResponse
  >('POST', '/api/v1/siteminder/poll'),
} as const
.sponsorInfoPane {
  margin: 1.5rem 0 4rem 0;
}

.header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  gap: 1.5rem;
}

.sponsorName {
  display: flex;
  flex-flow: row;
  gap: 0.875rem;
  align-items: center;
}

.star {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0.125rem;
}

.star > svg {
  width: inherit;
  height: inherit;
}

.divider {
  margin: 1rem 0 !important;
}

.contactList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 1.25rem;
  max-width: 900px;
}

.contactList > div {
  flex: 0 0 33.33%;
  padding-right: 1rem;
}

import { Card, Link } from '@component-library/helios'

import { BenefitStatus, sortCoverages } from '../../utils/benefit'
import { formatDateFullMonthWithYear } from '../../utils/format'

import styles from './BenefitCard.module.css'
import { getBenefitPolicyStatusLabel, statusToMessage, statusToVariant } from './Status'

type BenefitCardProps = {
  policy: Policy | null
  dentalPolicy?: string
  dentalIdCardUrl: string
  healthNavigatorUrl: string
}

export const BenefitCard = ({ policy, dentalPolicy, dentalIdCardUrl, healthNavigatorUrl }: BenefitCardProps) => {
  const coverages = policy?.coverages ?? []
  const sortedCoverages = sortCoverages(coverages)
  return (
    <>
      <div className={styles.stackY64}>
        {sortedCoverages.map((coverage) => {
          const label = getBenefitPolicyStatusLabel(coverage.effectiveDate, coverage.status)

          return (
            <div key={coverage.benefit} className={styles.stackY16}>
              <Card
                category={<h4>{coverage.benefit}</h4>}
                orientation="horizontal"
                badge={{
                  label,
                  variant: statusToVariant[label.toUpperCase()],
                  showPopover: false,
                  popoverBody: statusToMessage[label.toUpperCase()],
                }}
                heading=""
              >
                <div className={styles.cardInnerContainer}>
                  {/* hide date container entirely when terminated */}
                  {coverage.status === BenefitStatus.TERMINATED ? null : (
                    <div className={styles.dateContainer}>
                      <div className={styles.dateInnerContainer}>
                        <div className={styles.cardLabel}>Effective</div>
                        <div>
                          {coverage.effectiveDate ? formatDateFullMonthWithYear(new Date(coverage.effectiveDate)) : ''}
                        </div>
                      </div>
                      <div className={styles.dateInnerContainer}>
                        <div className={styles.cardLabel}>
                          {coverage.status !== BenefitStatus.TERMINATED ? 'Renews' : 'Terminated'}
                        </div>
                        <div>
                          {coverage.anniversaryDate
                            ? formatDateFullMonthWithYear(new Date(coverage.anniversaryDate))
                            : ''}
                        </div>
                      </div>
                    </div>
                  )}
                  {['basdental', 'dentalppo', 'dentalprepd', 'sldental', 'voldental'].includes(
                    coverage.benefitType.toLowerCase(),
                  ) &&
                    coverage.status.toUpperCase() !== 'TERMINATED' &&
                    policy?.policyStatus.toUpperCase() === 'ISSUED' && (
                      <div>
                        <Link
                          onClick={() => {
                            window.open(`${dentalIdCardUrl}=${dentalPolicy}`, '_blank')
                          }}
                        >
                          ID card
                        </Link>
                      </div>
                    )}
                  {coverage.benefitType.toLowerCase() === 'hlthnavg' &&
                    coverage.status.toUpperCase() !== 'TERMINATED' && (
                      <div>
                        <Link
                          onClick={() => {
                            window.open(`${healthNavigatorUrl}`, '_blank')
                          }}
                        >
                          Member card
                        </Link>
                      </div>
                    )}
                </div>
              </Card>
            </div>
          )
        })}
      </div>
    </>
  )
}

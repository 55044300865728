.dropdownButton {
  background-color: var(--brand-primary-400) !important;
  outline: none !important;
  height: 48px;
  min-width: 200px;
  padding: 3px 11px !important;
  border-radius: 4px !important;
}

.dropdownIcon {
  padding: 0 !important;
  width: 1rem;
  justify-content: center;
}

.dropdownButton .dropdownLabel {
  color: var(--color-tertiary-900);
  font-size: 1rem;
  font-weight: 700;
  margin-left: auto;
}

.dropdownIcon .dropdownLabel {
  display: none;
}

.paddingOne {
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
}

import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { useGetFavoritedSponsors } from '../../api/hooks/sponsor'
import { useGetFavoriteSponsorIds } from '../../api/hooks/useGetFavoriteSponsorIds'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { BookOfBusinessFilterContext, type BookOfBusinessFilter } from '../../context/BookOfBusinessFilterContext'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { BenefitStatus, filterSponsorsBenefitsByBenefitStatus } from '../../utils/benefit'

import { useEmptyText } from './useEmptyText'

export const StarredSponsors: FC = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const [filter, setFilter] = useState<BookOfBusinessFilter>({ ...sort })
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounced(searchQuery, 500)
  const auth = useAuth()
  const enabled = auth.user?.permissions.isInternalUser === false
  const favoriteIds = useGetFavoriteSponsorIds({ params: { brokerId: auth.user?.brokerID ?? '' } })
  const starredSponsors = useGetFavoritedSponsors(
    {
      query: {
        q: debouncedSearchQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )

  const emptyText = useEmptyText(
    filter.q,
    'There are no clients here yet. When you  click the star next to a client, it will appear here.',
  )

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchQueryChange() {
      if (searchQuery.length > 0 && searchQuery === debouncedSearchQuery) onPageChange(1)
    },
    [debouncedSearchQuery, onPageChange, searchQuery],
  )

  useEffect(() => {
    setFilter((prev) => ({ ...prev, q: debouncedSearchQuery, favorite: 'true' }))
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery) {
      analytics.track('book of business table:search starred employers', {
        query: debouncedSearchQuery,
      })
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery.length > 0 && starredSponsors.data?.bookOfBusiness?.totalRecords === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty starred employers query`,
      })
  }, [debouncedSearchQuery, starredSponsors.data?.bookOfBusiness?.totalRecords])

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query)
  }

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download starred employers',
    })
  }

  return (
    <BookOfBusinessFilterContext.Provider value={filter}>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.starred}
        downloadButtonProps={{
          disabled: starredSponsors.data?.bookOfBusiness?.totalRecords == 0,
          text: 'Download starred clients',
          fileType: 'starred',
          onDownloadClick: () => handleDownloadClick(),
        }}
        label="Search in starred..."
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
      />
      <QueryResultUnconditional query={starredSponsors}>
        {(data) => {
          const sponsors = filterSponsorsBenefitsByBenefitStatus(data?.bookOfBusiness?.sponsors ?? [], [
            BenefitStatus.RISK_APPROVED,
          ])
            // Filter sponsor by current value in favorite store (this store use optimistic update).
            .filter((sponsor) => favoriteIds.data?.sponsorIds.includes(sponsor.sponsorId))

          return (
            <BookOfBusinessTable
              type="starred"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </BookOfBusinessFilterContext.Provider>
  )
}

import { TextField } from '@component-library/helios'
import type { FC } from 'react'

import { trackEvent } from '../../utils/analytics'
import { BookOfBusinessDownloadButton } from '../BookOfBusinessDownloadButton/BookOfBusinessDownloadButton'

import styles from './BookOfBusinessActionBar.module.css'

export enum BookOfBusinessTabKey {
  allSponsors = 'active employers',
  recentlyViewed = 'recently viewed',
  starred = 'starred',
  terminated = 'terminated',
}

export type BookOfBusinessActionBarProps = {
  downloadButtonProps: {
    disabled: boolean
    text: string
    fileType: string
    onDownloadClick: () => void
  }
  label: string
  tab: BookOfBusinessTabKey
  searchQuery: string
  onSearchQueryChange: (query: string) => void
}

export const BookOfBusinessActionBar: FC<BookOfBusinessActionBarProps> = ({
  downloadButtonProps,
  label,
  tab,
  searchQuery,
  onSearchQueryChange,
}) => {
  const handleSearchInputClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: `book of business table:search bar click ${tab}`,
    })
    analytics.track('book of business table:search bar click', {
      type: 'other',
      action: 'clk',
    })
  }

  const handleDownloadClick = () => {
    downloadButtonProps.onDownloadClick()
  }

  return (
    <section className={styles.bookOfBusinessActionBar}>
      <form className={styles.searchContainer}>
        <TextField
          id="bookOfBusinessActionBar"
          data-testid="bookOfBusinessActionBar"
          label={label}
          value={searchQuery}
          icon="fal fa-search"
          iconPosition="after"
          onChange={(event) => {
            onSearchQueryChange(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
          onClick={() => handleSearchInputClick()}
        />
      </form>
      <div className={styles.downloadContainer}>
        <BookOfBusinessDownloadButton
          text={downloadButtonProps.text}
          disabled={downloadButtonProps.disabled}
          onClick={() => handleDownloadClick()}
          fileType={downloadButtonProps.fileType}
        />
      </div>
    </section>
  )
}

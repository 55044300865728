import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const useDefaultTabNavigation = (tabs: Array<string>, defaultTab: string) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isDefaultTabSelected = pathname.endsWith(defaultTab)
  const isAnyTabSelected = tabs.some((tab) => pathname.endsWith(tab))

  useEffect(() => {
    if (!isAnyTabSelected && !isDefaultTabSelected) {
      navigate(`.${defaultTab}`, { replace: true })
    }
  }, [navigate, defaultTab, isAnyTabSelected, isDefaultTabSelected])
}

export const formatUrl = (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathVariables: Record<string, any> = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryString: Record<string, any> = {},
): string => {
  const formattedPath = Object.entries(pathVariables).reduce((formatted, [key, value]) => {
    return formatted.replace(`{${key}}`, value)
  }, path)

  const queryParams = new URLSearchParams(queryString)
  const formattedQueryString = queryParams.toString()

  return formattedQueryString ? `${formattedPath}?${formattedQueryString}` : formattedPath
}
